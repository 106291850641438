import React, { useState } from 'react';
import {TextField, Typography, IconButton, ThemeProvider  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useStyles, theme, spanStyle } from '../styles'; // Importing styles


const AccountSettingsModal = ({ onClose, email: initialEmail, username: initialUsername, setTriggerReload }) => {

  const [email, setEmail] = useState(initialEmail);
  const [username, setUsername] = useState(initialUsername);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const [showChangePasswordFields, setShowChangePasswordFields] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({ current: '', new: '', confirm: '' });
  const classes = useStyles();

  const validatePasswords = async (currentPassword, newPassword) => {
    let isValid = true;
    let errors = { current: '', new: '', confirm: '' };
    const userId = localStorage.getItem('userId');
  
    try {
      const response = await fetch(`${API_URL}/api/user/validate-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, currentPassword, newPassword }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Error validating password');
      }
    } catch (error) {
      console.error('Error validating current password', error);
      isValid = false;
      errors.current = error.message; // Set error message
      setErrorMessages(errors); // Update state with errors
      return isValid;
    }
  
    // Assuming no errors, password change was successful
    console.log('Password change successful');
    setErrorMessages(errors); // Reset or update error messages as needed
    return isValid;
  };

  
// Update the handleChangePassword function to handle the "New password is required." error
const handleChangePassword = async (currentPassword, newPassword, confirmPassword) => {
  console.log("handleChangePassword called");

  // Reset error messages
  setErrorMessages({ current: '', new: '', confirm: '' });

  // Validate new password fields
  if (!newPassword) {
    console.log("New password is required.");
    setErrorMessages(prevState => ({ ...prevState, new: 'New password is required.' }));
    return;
  }
  if (newPassword !== confirmPassword) {
    setErrorMessages(prevState => ({ ...prevState, confirm: 'Passwords do not match.' }));
    return;
  }

  // Call validatePasswords to check current password and then update if valid
  if (await validatePasswords(currentPassword, newPassword)) {
    // Password change was successful, handle accordingly
    onClose(); // Close modal or notify user
  } else {
    // Handle validation or update failure
    console.error('Error changing password');
    setErrorMessages(prevState => ({ ...prevState, current: 'Password is incorrect. Please try again.' }));
  }
};
const handleToggleChangePassword = () => {
  setShowChangePasswordFields(!showChangePasswordFields);
  setCurrentPassword('');
  setNewPassword('');
  setConfirmPassword('');
};

  const { t } = useTranslation();

  const handleDesktopClose = () => {
    onClose();
  };

  const handleSaveChanges = async () => {
    // Retrieve userId from localStorage
    const userId = localStorage.getItem('userId');
  
    try {
        const response = await fetch(`${API_URL}/api/user/update`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, email, username }),
        });
  
        if (response.ok) {
          console.log('Update successful');
          setEmail(email); // Update email state
          setUsername(username); // Update username state
          // Update email and username in localStorage
          localStorage.setItem('email', email);
          localStorage.setItem('username', username);
          setTriggerReload(prev => !prev);
          onClose();
          // Optionally, refresh user data or show a success message
        } else {
          console.error('Failed to update user');
          // Handle errors, show messages to the user
        }
    } catch (error) {
      console.error('Error updating user', error);
      // Handle errors, show messages to the user
    }
  };

  return (
    <ThemeProvider theme={theme}>
  <div className={classes.modalContainer}>
    <div className={classes.header}>
      <Typography variant="h6" className={classes.title}>
        {t('Account settings')}
      </Typography>
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon onClick={handleDesktopClose} />
      </IconButton>
    </div>
    <form className={classes.form}>
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={classes.textField}
      />
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className={classes.textField}
      />
      {showChangePasswordFields && (
        <>
          <TextField
            label="Current Password"
            type="password"
            variant="outlined"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            error={!!errorMessages.current}
            helperText={errorMessages.current}
            className={classes.textField}
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!errorMessages.new}
            helperText={errorMessages.new}
            className={classes.textField}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errorMessages.confirm}
            helperText={errorMessages.confirm}
            className={classes.textField}
          />
        </>
      )}
      {/* Shared button container */}
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '10px', width: '100%' }}>
        <IconButton onClick={handleToggleChangePassword} className={classes.button}>
          <span style={spanStyle}>
            {showChangePasswordFields ? t('Cancel Change Password') : t('Change Password')}
          </span>
        </IconButton>

        {showChangePasswordFields && (
          <IconButton
            variant="contained"
            className={classes.button}
            onClick={() => handleChangePassword(currentPassword, newPassword, confirmPassword)}
          >
            <span style={spanStyle}>
              {t('Change Password')}
            </span>
          </IconButton>
        )}

        <IconButton
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          className={classes.button}
        >
          <span style={spanStyle}>
            {t('Save Changes')}
          </span>
        </IconButton>
      </div>
    </form>
  </div>
</ThemeProvider>
  );
};

export default AccountSettingsModal;