import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography, MobileStepper} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import {useSwipeable} from 'react-swipeable';
import CustomCheckBox from '../assets/CustomCheckBox';
import { useTranslation } from 'react-i18next';
import CheckAssetCircle from '../assets/CheckAssetCircle';
import sampleimage from '../img/sampleimage.png';
const useStyles = makeStyles((theme) => ({
  gridItemContainer: {
    position: 'relative',
    flexBasis: '100%', // Default to full width
    [theme.breakpoints.up('sm')]: {
      flexBasis: '40%', // 2 items per row on small devices
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '30.33%', // 3 items per row on medium devices
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: '23%', // 4 items per row on large devices
    },
  },
  checkboxContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  gridItemContainerMobile: {
position: 'relative',
flexBasis: '100%'
  },
  stepperDots: {
    backgroundColor: '#525866',
  },
  activeDot: {
    backgroundColor: 'white',
  }
}));

const RenderGridItem = ({ data, handleGridItemClick, handleCheckboxChange, isChecked, isSelectAllChecked   }) => {

  const { t , i18n} = useTranslation();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        console.log('Next picture');
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
      };
    
      const handleStepChange = (step) => {
        setActiveStep(step);
      };

  // Call useSwipeable after handleNext and handleBack are defined
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handleBack
  });

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1); // Default to 1 for USD
  const [currency, setCurrency] = useState('euro');
  async function fetchExchangeRate(fromCurrency, toCurrency) {
    // Fetch the exchange rate from a financial API
    // For simplicity, returning a static value
    return 117.5; 
  }
  
  useEffect(() => {
    // Detect language change and update currency and exchange rate accordingly
    const updateCurrencyAndRate = async () => {
      if (i18n.language === 'sr') {
        const rate = await fetchExchangeRate('euro', 'rsd');
        setExchangeRate(rate);
        setCurrency('rsd');
      } else {
        setExchangeRate(1); // Reset to default for USD
        setCurrency('euro');
      }
    };

    updateCurrencyAndRate();
  }, [i18n.language]);

  const formattedConvertedPrice = (data.price * exchangeRate).toLocaleString('sr-RS', {
    maximumFractionDigits: 2, // Adjust this as needed for the desired number of decimal places
    minimumFractionDigits: 2 // Ensures consistency in the display of prices
  });
  // Handle checkbox change
  const handleChange = () => {
    const newIsChecked = !isChecked; // Toggle isChecked state

    if (newIsChecked) {
      console.log('Selected asset:', data);
      handleCheckboxChange(data, newIsChecked); // Pass the updated state to the parent componenty
    } else if (isSelectAllChecked){
      handleCheckboxChange(data, !isSelectAllChecked); // Pass the updated state to the parent componenty
    
    } else {
      console.log('Deselected asset:', data);
    }
  };
  let images = [];
  let numImages = 0;

  if (data && data.images) {

    // Sort the images array by imageId
    images = data.images.sort((a, b) => a.imageId - b.imageId);

    numImages = Math.min(images.length, 5);
} else {
    console.log(`Data with ID ${data.id} does not have images`);
}
  
  const maxSteps = numImages > 1 ? numImages : 0;

  
  const renderContent = () => {
    if (isMobile) {
        // Placeholder for mobile content
        return (
            <Grid
            {...swipeHandlers}
      item
      key={data.id}
      xs={12}
      sm={6}
      md={4}
      lg={3}
      className={classes.gridItemContainerMobile}
      style={{ position: 'relative', width: '357px', maxHeight: '400px', backgroundColor: '#0D1428', margin: '0px 15px 8px 15px', overflow: 'hidden', }}
    >
                <IconButton onClick={() => handleChange(data)} style={{ position: 'absolute', top: 0, right: 0 }}>
          <CheckAssetCircle isChecked={isChecked} handleChange={() => handleCheckboxChange(data, !isChecked)}  style={{ left: '20%', position: 'relative' ,  width: '30px', height: '30px'}} />
          </IconButton>
            <img
              src={images[activeStep]?.imageUrl  || sampleimage}
              alt={data.name}
              loading="lazy" 
              style={{ width: '100%', borderRadius: '20px' }}
              onClick={() => handleGridItemClick(data)}
            />
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            style={{ position: 'relative', justifyContent: 'center', bottom: 30, zIndex: 1, background: 'transparent' }}
            classes={{ dot: classes.stepperDots, dotActive: classes.activeDot }}
            onChange={(step) => handleStepChange(step)}
          />
      <div onClick={() => handleGridItemClick(data)}>
      <Typography variant="body1" style={{ 
                  color: '#00D0BD', 
                  fontWeight: 500, 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  width: '200px', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingLeft: '10px'
                }}>
          {data.name}
        </Typography>
        <Typography variant="body1" style={{ 
                  color: '#F4F4F4', 
                  fontWeight: 300, 
                  fontSize: '14px',
                  width: '200px', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap', 
                  paddingLeft: '10px'
              }}>
                  {data.address}
              </Typography>
              <Typography variant="body1" style={{ color: '#F4F4F4', fontWeight: 300, fontSize: '14px', paddingLeft: '10px' }}>
                {data.squareFootage} {t('ft²')}
              </Typography>
        <Typography variant="body1" style={{ color: '#F4F4F4', fontWeight: 500, fontSize: '14px', paddingLeft: '10px' }}>
        {currency === 'euro' ? `€${data.price}` : `${formattedConvertedPrice} rsd`}
        </Typography>
      </div>

      {numImages > 1 && (
        <>
          <IconButton
            style={{
              position: 'absolute',
              top: '35%',
              left: '0px',
              transform: 'translateY(-50%)',
              opacity: 0,
              transition: 'opacity 0.3s',
              zIndex: 1,
              color: 'rgb(0, 208, 189)'
            }}
            onClick={handleBack}
            onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
            onMouseLeave={(e) => e.currentTarget.style.opacity = 0}
          >
            <KeyboardArrowLeftIcon style={{ width: '30px', height: '30px' }} />
          </IconButton>

          <IconButton
            style={{
              position: 'absolute',
              top: '35%',
              right: '0px',
              transform: 'translateY(-50%)',
              opacity: 0,
              transition: 'opacity 0.3s',
              zIndex: 1,
              color: 'rgb(0, 208, 189)'
            }}
            onClick={handleNext}
            onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
            onMouseLeave={(e) => e.currentTarget.style.opacity = 0}
          >
            <KeyboardArrowRightIcon style={{ width: '30px', height: '30px' }} />
          </IconButton>

        </>
      )}
    </Grid>
        );
    } else {
        // Placeholder for desktop content
        return (
            <Grid
            item
            key={data.id}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.gridItemContainer}
            style={{ position: 'relative', width: '266px', height: '236px', backgroundColor: '#0D1428', margin: '0px 0px 8px 8px', overflow: 'hidden' }}
          >
          <IconButton onClick={() => handleChange(data)} style={{ position: 'absolute', top: 0, right: 0 }}>
          <CheckAssetCircle isChecked={isChecked} handleChange={() => handleCheckboxChange(data, !isChecked)}  style={{ left: '20%', position: 'relative' ,  width: '30px', height: '30px'}} />
          </IconButton>
            <img
              src={images[activeStep]?.imageUrl  || sampleimage}
              loading="lazy" 
              alt={data.name}
              style={{ width: '100%', height: '146px', borderRadius: '20px' }}
              onClick={() => handleGridItemClick(data)}
            />
      
            <div className={classes.textOverlay} style={{ position: 'absolute', bottom: '-10px', left: 0, right: 0, padding: '10px', width: '220px' }} onClick={() => handleGridItemClick(data)}>
            <Typography variant="body1" style={{ 
                  color: '#00D0BD', 
                  fontWeight: 500, 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  width: '200px', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                {data.name}
              </Typography>
              <Typography variant="body1" style={{ 
                  color: '#F4F4F4', 
                  fontWeight: 300, 
                  fontSize: '14px',
                  width: '200px', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
              }}>
                  {data.address}
              </Typography>
              <Typography variant="body1" style={{ color: '#F4F4F4', fontWeight: 300, fontSize: '14px' }}>
                {data.squareFootage} {t('ft²')}
              </Typography>
              <Typography variant="body1" style={{ color: '#F4F4F4', fontWeight: 500, fontSize: '14px' }}>
              {currency === 'euro' ? `€${data.price}` : `${formattedConvertedPrice} rsd`}
              </Typography>
            </div>
      
            {numImages > 1 && (
              <>
                <IconButton
                  style={{
                    position: 'absolute',
                    top: '35%',
                    left: '0px',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    zIndex: 1,
                    color: 'rgb(0, 208, 189)'
                  }}
                  onClick={handleBack}
                  onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                  onMouseLeave={(e) => e.currentTarget.style.opacity = 0}
                >
                  <KeyboardArrowLeftIcon style={{ width: '30px', height: '30px' }} />
                </IconButton>
      
                <IconButton
                  style={{
                    position: 'absolute',
                    top: '35%',
                    right: '0px',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    zIndex: 1,
                    color: 'rgb(0, 208, 189)'
                  }}
                  onClick={handleNext}
                  onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                  onMouseLeave={(e) => e.currentTarget.style.opacity = 0}
                >
                  <KeyboardArrowRightIcon style={{ width: '30px', height: '30px' }} />
                </IconButton>
      
                <MobileStepper
                  variant="dots"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  style={{ position: 'absolute', bottom: 90, left: '50%', transform: 'translateX(-50%)', zIndex: 1, background: 'transparent' }}
                  classes={{ dot: classes.stepperDots, dotActive: classes.activeDot }}
                  onChange={(step) => handleStepChange(step)}
                />
              </>
            )}
          </Grid>
        );
    }
};

return (
  <React.Fragment>
  {renderContent()}

</React.Fragment>
);
};

export default RenderGridItem;
