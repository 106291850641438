import React, { useEffect, useState } from 'react';
import './App.css';
import HomePage from './components/HomePage';
import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';
import { getMessaging, getToken } from 'firebase/messaging';
import InstallPWAButton from './InstallPWAButton';
import { Button } from '@mui/material';

const App = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user's authentication status
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [permissions, setPermissions] = useState([]); // Add a new state variable for permissions
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const messaging = getMessaging();
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
      if (currentToken) {
        console.log(currentToken);
        // Store the token in localStorage or state to use after login
        localStorage.setItem('fcmToken', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }, []);

  const handleEnableNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
        if (token) {
          console.log('FCM Token:', token);
          sendTokenToServer(token);
        } else {
          console.log('No registration token available.');
        }
      } else {
        console.log('Notification permission denied.');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };
  

  const sendTokenToServer = (token) => {
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error('User ID not found');
      return;
    }
    fetch(`${API_URL}/api/save-fcm-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, userId }), // Include userId in the request body
    }).catch(err => console.error('Error sending token to server:', err));
  };

  const handleSignUpClick = () => {
    setIsSignUp(true);
  };

  const handleSignInClick = () => {
    setIsSignUp(false);
  };

  const handleLogin = (userId, username, email, token, permissions) => { // Add permissions parameter
    console.log('handleLogin called with permissions:', permissions);
    setIsLoggedIn(true);
    setUserId(userId); // Set the userId when user logs in
    setUsername(username);
    setEmail(email);
    setPermissions(permissions);
    console.log('Permissions state after handleLogin:', permissions);
    localStorage.setItem('userId', userId); // Store authentication state
    localStorage.setItem('username', username);
    localStorage.setItem('email', email);
    localStorage.setItem('token', token);
    setTriggerReload(prev => !prev);

     // After storing user details, send the FCM token to the server
  const fcmToken = localStorage.getItem('fcmToken'); // Retrieve the FCM token stored earlier
  if (fcmToken) {
    sendTokenToServer(fcmToken);
  }
};

const [isLoading, setIsLoading] = useState(true); // Add a new state variable for loading status

useEffect(() => {
  const storedUserId = localStorage.getItem('userId');
  const username = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  if (storedUserId) {
    setIsLoggedIn(true);
    setUserId(storedUserId);
    setUsername(username);
    setEmail(email);
    fetchPermissions(storedUserId); 
  } else {
    setIsLoading(false); // Set loading to false if user is not logged in
  }
}, [triggerReload]);

const fetchPermissions = async (userId) => {
  const response = await fetch(`${API_URL}/api/users/${userId}/permissions`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  const data = await response.json();
  setPermissions(data.permissions.filter(permission => permission !== null));
  setIsLoading(false); // Set loading to false after permissions have been fetched
};
if (isLoading) {
  return <div>Loading...</div>; // Render a loading message while permissions are being fetched
}

// isIOS function
function isIOS() {
  // Check the user agent for iOS device indicators
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || 
         (navigator.userAgent.includes("Mac") && "ontouchend" in document); // This checks for iPadOS 13 and above
};

return (
  <>
    {isLoggedIn ? (
      <HomePage isLoggedIn={isLoggedIn} userId={userId} username={username} email={email} setIsLoggedIn={setIsLoggedIn} permissions={permissions} setTriggerReload={setTriggerReload} isIOS={isIOS} handleEnableNotifications={handleEnableNotifications}/>
    ) : isSignUp ? (
      <SignUpForm onSignInClick={handleSignInClick} />
    ) : (
      <>
        <SignInForm onSignUpClick={handleSignUpClick} onLogin={handleLogin} />
        {isIOS() && <InstallPWAButton />}
      </>
    )}
  </>
);
};

export default App;