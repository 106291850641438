// Importing necessary dependencies and components
import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale)
// Update the locale to English and set the start of the week to Monday
dayjs.updateLocale('en', {
    weekStart: 1,
})
const MobileCalendar = (props) => {
  const { date, width, ...other } = props;
 // Define a function to format the day of the week
  const dayOfWeekFormatter = (day, date) => {
    const abbreviatedDay = dayjs(date).format('dd'); 
    return (
      <div>
        <div className="custom-week-text">{abbreviatedDay}</div>
      </div>
    );
  };
// Define the styles for the calendar header
  const styleHeaderCalendar = {
    '.MuiPickersCalendarHeader-root': {
      position: 'relative',
      alignItems: 'center',
      color: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      padding: '0px',
    },
    '.MuiPickersCalendarHeader-labelContainer': { marginRight: 0, justifyContent: 'space-around' },
    '.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button':
      {
        position: 'absolute',
        left: '38px',
        top: '-8px',
        color: '#00D0BD',
      },
    '.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button':
      {
        position: 'absolute',
        top: '-8px',
        color: '#00D0BD',
        right: '68px',
      },
      '& .MuiTypography-root': {
        color: '#FFFFFF',
        padding: '0px',
        margin: '0px',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 'normal',
        height: '24px'
      },
      '& .MuiCalendarPicker-weekText': {
        display: 'none', 
      },
      '& .MuiPickersDay-root': {
        color: '#FFFFFF',
        padding: '0px',
        margin: '0px',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 'normal',
        height: '24px'
      },
      '& .custom-week-text': {
        // Style for custom week text
        color: '#FFFFFF',
        fontSize: '14px',
      },
      '& .full-week-text': {
        // Style for full week text
        color: '#FFFFFF',
        fontSize: '14px',
      },
      '& .MuiDayCalendar-weekContainer ': {
        margin: '0px 0px 6px 0px',
        justifyContent: 'center',
      },
      '& .MuiDayCalendar-header': {
        justifyContent: 'center',
        width: width
      },
      '& .MuiPickersSlideTransition-root': {
        width: width,
      },
      '& .MuiDayCalendar-weekNumber': {
        background: '#0E1529', // Change background color of week number label
        color: '#fff', // Change text color of week number label
        fontSize: '12px',
        fontFamily: 'Montserrat',
        fontWeight: 200,
        fontStyle: 'normal',
        lineHeight: 'normal',
        width: '29.5px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
  };
    // Customize week number header text
    const weekNumberHeaderText = '#';

    // Customize week number text
    const weekNumberText = (weekNumber) => `${weekNumber}`;
    
// Render the CustomCalendar component. It includes a LocalizationProvider and DateCalendar component.
  // The date and other props, as well as the dayOfWeekFormatter function and styles, are passed as props to the DateCalendar component.
  return (
    <div style={{ width: width }}>
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en" dateFormats={{ weekStartsOn: 1 }}         localeText={{
          calendarWeekNumberHeaderText: weekNumberHeaderText,
          calendarWeekNumberText: weekNumberText,
        }}>
      <DateCalendar
        {...other}
        date={date}
        dayOfWeekFormatter={dayOfWeekFormatter}
        showDaysOutsideCurrentMonth
        fixedWeekNumber={4}
        views={['day']}
        displayWeekNumber
        disableHighlightToday
        slotProps={{calendarHeader: {
            sx: {
              '.MuiPickersCalendarHeader-label': {
                fontSize: '11px',
                lineHeight: '24px',
                color: '#FFFFFF',
                fontWeight: 400,
                paddingTop: '2px'
              },
            },
          },}}
          sx={styleHeaderCalendar}
      />
    </LocalizationProvider>
    </div>
  );
};

export default MobileCalendar;