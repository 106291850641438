import React from 'react';

const HandTap = ({ color }) => {

  return (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_669_7143)">
<path d="M7 9.5C7 7.77609 7.68482 6.12279 8.90381 4.90381C10.1228 3.68482 11.7761 3 13.5 3C15.2239 3 16.8772 3.68482 18.0962 4.90381C19.3152 6.12279 20 7.77609 20 9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.00001 28L4.33501 21.75C4.00872 21.1761 3.92267 20.4964 4.09564 19.8593C4.26861 19.2222 4.68656 18.6794 5.25828 18.3493C5.83 18.0192 6.50907 17.9287 7.14731 18.0974C7.78555 18.2661 8.33114 18.6805 8.66501 19.25L11 23V9.5C11 8.83696 11.2634 8.20107 11.7322 7.73223C12.2011 7.26339 12.837 7 13.5 7C14.1631 7 14.7989 7.26339 15.2678 7.73223C15.7366 8.20107 16 8.83696 16 9.5V16.5C16 15.837 16.2634 15.2011 16.7322 14.7322C17.2011 14.2634 17.837 14 18.5 14C19.1631 14 19.7989 14.2634 20.2678 14.7322C20.7366 15.2011 21 15.837 21 16.5V18.5C21 17.837 21.2634 17.2011 21.7322 16.7322C22.2011 16.2634 22.837 16 23.5 16C24.1631 16 24.7989 16.2634 25.2678 16.7322C25.7366 17.2011 26 17.837 26 18.5V23C26 26 25 28 25 28" stroke={color}  strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_669_7143">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default HandTap;
