import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: 'none', // change color of filled values
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'none', // change color of borders on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FFF', // change color of borders when focused
            },
            '.MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: '#FFF', // change color of text when disabled
                padding: '10px'
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(0, 208, 189)'
            }
          },
          notchedOutline: {
            borderWidth: '1px',
            borderColor: 'rgb(0, 208, 189) !important',
            borderStyle: 'none none solid none', // top right bottom left
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: '#FFF', // change color of labels,
              paddingLeft: '7px'
            },
          },
        },
      },
    },
  });
const useStyles = makeStyles({
  customFont: {
    fontFamily: 'Montserrat, sans-serif',
    color: '#FFFFFF',
    fontSize: '15px'
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tableView: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  clientCard: {
    border: '1px solid #ccc',
    margin: '10px 0',
    padding: '10px',
  },
  select: {
    '&:before': {
      borderColor: '#FFF', // change color of underline
    },
    '&:after': {
      borderColor: '#FFF', // change color of underline
    },
  },
  icon: {
    fill: 'rgb(0, 208, 189)', // change color of dropdown arrow
  },
  selectMenu: {
    color: '#FFF', // change color of selected value
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid rgb(0, 208, 189)',
    },
    '&:after': {
      borderBottom: '1px solid rgb(0, 208, 189)',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid rgb(0, 208, 189)',
      },
  },
  dialogPaper: {
    position: 'absolute',
    right: "10%",
    top: '20%',
    height: '400px',
    overflowY: 'auto',
    width: '-webkit-fill-available',
    backgroundColor: '#1A2033',
  },
});

export { theme, useStyles };