import React from 'react';

const CheckedAssets = () => {


  return (
<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.1695 16.518C29.2944 16.6428 29.3935 16.791 29.4611 16.9542C29.5288 17.1173 29.5636 17.2922 29.5636 17.4688C29.5636 17.6453 29.5288 17.8202 29.4611 17.9833C29.3935 18.1465 29.2944 18.2947 29.1695 18.4195L19.7632 27.8257C19.6384 27.9506 19.4902 28.0498 19.3271 28.1174C19.164 28.185 18.9891 28.2198 18.8125 28.2198C18.6359 28.2198 18.4611 28.185 18.2979 28.1174C18.1348 28.0498 17.9866 27.9506 17.8618 27.8257L13.8306 23.7945C13.5784 23.5423 13.4368 23.2003 13.4368 22.8438C13.4368 22.4872 13.5784 22.1452 13.8306 21.893C14.0827 21.6409 14.4247 21.4993 14.7813 21.4993C15.1378 21.4993 15.4798 21.6409 15.732 21.893L18.8125 24.9753L27.2681 16.518C27.3929 16.3931 27.5411 16.294 27.7042 16.2264C27.8673 16.1587 28.0422 16.1239 28.2188 16.1239C28.3953 16.1239 28.5702 16.1587 28.7333 16.2264C28.8965 16.294 29.0447 16.3931 29.1695 16.518ZM38.9688 21.5C38.9688 24.955 37.9442 28.3324 36.0247 31.2051C34.1053 34.0778 31.377 36.3168 28.185 37.639C24.993 38.9612 21.4806 39.3071 18.092 38.6331C14.7034 37.9591 11.5908 36.2953 9.14774 33.8523C6.70469 31.4092 5.04095 28.2966 4.36692 24.908C3.69288 21.5194 4.03882 18.007 5.36099 14.815C6.68316 11.623 8.92217 8.89476 11.7949 6.97527C14.6676 5.05577 18.045 4.03125 21.5 4.03125C26.1315 4.03614 30.5719 5.87816 33.8469 9.15313C37.1218 12.4281 38.9639 16.8685 38.9688 21.5ZM36.2813 21.5C36.2813 18.5765 35.4144 15.7187 33.7902 13.288C32.166 10.8572 29.8575 8.96266 27.1565 7.84391C24.4556 6.72515 21.4836 6.43243 18.6163 7.00277C15.749 7.57311 13.1153 8.98088 11.0481 11.0481C8.98089 13.1153 7.57311 15.749 7.00278 18.6163C6.43244 21.4836 6.72516 24.4556 7.84391 27.1565C8.96267 29.8575 10.8572 32.166 13.288 33.7902C15.7187 35.4143 18.5766 36.2812 21.5 36.2812C25.4189 36.2768 29.176 34.7181 31.947 31.947C34.7181 29.176 36.2768 25.4189 36.2813 21.5Z" fill="#00D0BD"/>
</svg>
  );
};
  
export default CheckedAssets;
