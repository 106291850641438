import React from 'react';

const CheckAssetCircle = ({ isChecked, style, handleChange }) => {
    const fillColor = isChecked ? '#252A3A' : '#252A3A'; 
    const fillOpacity = isChecked ? '1.0' : '0.3'; 
    const fill = isChecked ? 'rgb(0, 208, 189)' : 'white';
  return (
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} onClick={handleChange}>
<rect width="39" height="39" rx="19.5" fill={fillColor} fillOpacity={fillOpacity}/>
<path d="M26.456 14.9815C26.5693 15.0947 26.6592 15.2291 26.7206 15.377C26.7819 15.525 26.8135 15.6836 26.8135 15.8438C26.8135 16.0039 26.7819 16.1625 26.7206 16.3105C26.6592 16.4584 26.5693 16.5928 26.456 16.706L17.9248 25.2373C17.8116 25.3506 17.6772 25.4405 17.5292 25.5018C17.3813 25.5631 17.2227 25.5947 17.0625 25.5947C16.9023 25.5947 16.7438 25.5631 16.5958 25.5018C16.4478 25.4405 16.3134 25.3506 16.2002 25.2373L12.544 21.581C12.3153 21.3523 12.1868 21.0422 12.1868 20.7188C12.1868 20.3953 12.3153 20.0852 12.544 19.8565C12.7727 19.6278 13.0828 19.4993 13.4063 19.4993C13.7297 19.4993 14.0398 19.6278 14.2685 19.8565L17.0625 22.652L24.7315 14.9815C24.8447 14.8682 24.9791 14.7783 25.127 14.7169C25.275 14.6556 25.4336 14.624 25.5938 14.624C25.7539 14.624 25.9125 14.6556 26.0605 14.7169C26.2084 14.7783 26.3428 14.8682 26.456 14.9815ZM35.3438 19.5C35.3438 22.6336 34.4145 25.6968 32.6736 28.3023C30.9327 30.9078 28.4582 32.9385 25.5631 34.1377C22.6681 35.3369 19.4824 35.6507 16.409 35.0393C13.3357 34.428 10.5126 32.919 8.29678 30.7032C6.081 28.4874 4.57203 25.6644 3.96069 22.591C3.34936 19.5176 3.66311 16.3319 4.86229 13.4369C6.06147 10.5418 8.0922 8.06734 10.6977 6.3264C13.3032 4.58547 16.3664 3.65625 19.5 3.65625C23.7007 3.66069 27.728 5.33136 30.6983 8.30168C33.6686 11.272 35.3393 15.2993 35.3438 19.5ZM32.9063 19.5C32.9063 16.8485 32.12 14.2565 30.6469 12.0519C29.1738 9.84724 27.08 8.12893 24.6304 7.11424C22.1807 6.09955 19.4851 5.83406 16.8846 6.35135C14.284 6.86863 11.8953 8.14545 10.0204 10.0203C8.14546 11.8952 6.86864 14.284 6.35135 16.8846C5.83407 19.4851 6.09956 22.1807 7.11425 24.6304C8.12893 27.08 9.84725 29.1738 12.0519 30.6469C14.2565 32.12 16.8485 32.9062 19.5 32.9062C23.0543 32.9022 26.4619 31.4885 28.9752 28.9752C31.4885 26.4619 32.9022 23.0543 32.9063 19.5Z" fill={fill}/>
</svg>

  );
};
  
export default CheckAssetCircle;
