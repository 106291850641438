import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '10px !important',
    textTransform: 'capitalize !important',
    fontSize: '15px !important',
    border: '1px solid #1F2C41',
    background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
    color: 'white !important',
    borderRadius: '15px !important',
    marginLeft: '10px !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 10px', // Adjust padding as needed
    paddingLeft: '0px'
  },
}));


const Notifications = ({initialMessages, updateNotificationCount, handleEnableNotifications, isIOS}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  
  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }

    // Fetch messages for the user
    const fetchMessages = async () => {
      const userId = localStorage.getItem('userId'); // Get userId from localStorage
      try {
        const response = await fetch(`${API_URL}/api/messages/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMessages(data);
        if (typeof updateNotificationCount === 'function') {
          updateNotificationCount(data.length);
        } else {
          console.error('updateNotificationCount is not a function');
        }
        console.log("data", data.lenght)
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []); // Removed userId from dependency array since it's now fetched from localStorage


  // Render different content based on the device type
  const renderContent = () => {
    const commonStyles = {
      color: 'white',
      textAlign: 'left',
      marginTop: '20px',
      paddingLeft: '15px',
      paddingBottom: '15px',
      fontWeight: 300,
      fontSize: '20px',
      fontFamily: 'Montserrat',
    };

    const renderMessages = () => {
      if (!Array.isArray(messages) || messages.length === 0) {
        return <div style={{color: 'white', fontSize: '15px', paddingLeft: '10px', paddingRight: '10px'}}>{t('No notifications')}</div>;
      }
    
      return messages.map((message, index) => {
        // Assuming message.data contains the dynamic parts and message.messageKey contains the translation key
        const { messageKey, data } = message;
        return (
          <div key={index} style={{color: 'white', fontSize: '15px', paddingLeft: '10px', paddingRight: '10px', borderBottom: '1px solid gray', marginBottom: '10px'}}>
            {t(messageKey, data)}
          </div>
                    
        );
      });
    };

    const renderInitialMessage = () => {
      if (!Array.isArray(initialMessages)) { // Corrected to check initialMessages
        // Return null or some fallback UI if initialMessages is not an array
        return null;
      }
    
      return initialMessages.map((message, index) => (
        <div key={index} style={{color: 'white', fontSize: '15px', paddingLeft: '10px', paddingRight: '10px'}}>
          {message}
        </div>
      ));
    };


    if (isMobile) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: '0px',
            width: '100%',
            height: 'calc(100% - 61px)',
            flexShrink: 0,
            borderRight: '1px solid #333840',
            background: '#1A2033',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
    <div className={classes.header}>
      <Typography variant="body1" style={commonStyles}>
        {t('Notifications')}
      </Typography>
      {isIOS() && <Button onClick={handleEnableNotifications} className={classes.button}>{t('Enable Notifications')}</Button>}
    </div>
          <div style={{ borderTop: '1px solid gray', marginBottom: '10px' }}></div>
          {renderInitialMessage()}
          {renderMessages()}
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: '60px',
            width: '250px',
            height: '100vh',
            flexShrink: 0,
            borderRight: '1px solid #333840',
            background: '#1A2033',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Typography variant="body1" style={commonStyles}>
            {t('Notifications')}
          </Typography>
          <div style={{ borderTop: '1px solid gray', marginBottom: '10px' }}></div>
          {renderInitialMessage()}
          {renderMessages()}
        </div>
      );
    }
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
};

export default Notifications;