import React from 'react';

const Envelope = ({ fill }) => {

  return (
<svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_605_6581)">
<path d="M43.75 10.9375L25 28.125L6.25 10.9375" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.25 10.9375H43.75V37.5C43.75 37.9144 43.5854 38.3118 43.2924 38.6049C42.9993 38.8979 42.6019 39.0625 42.1875 39.0625H7.8125C7.3981 39.0625 7.00067 38.8979 6.70765 38.6049C6.41462 38.3118 6.25 37.9144 6.25 37.5V10.9375Z" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21.5918 25L6.73242 38.6211" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M43.2676 38.6211L28.4082 25" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_605_6581">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default Envelope;
