import React from 'react';

const ListMenu = ({ color, width, height }) => (
<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_226_5649)">
<path d="M8.75 28H47.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.75 14H47.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.75 42H47.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_226_5649">
<rect width="56" height="56" fill="white"/>
</clipPath>
</defs>
</svg>
);
  
export default ListMenu;