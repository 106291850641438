import { useState } from 'react';
import '../styles/LoginForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { login } from '../services/authService';

const SignInForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleLogin = async () => {
        if (username === '') {
            setUsernameError('Please enter your username.');
            setPasswordError('');
        } else if (password === '') {
            setUsernameError('');
            setPasswordError('Please enter your password.');
        } else {
            try {
                const data = await login(username, password);
                localStorage.setItem('token', data.token);
                props.onLogin(data.user.id, data.user.username, data.user.email, data.token, data.user.permissions);
            } catch (error) {
                console.error('Error logging in:', error);
                // Example of parsing error message for detailed error handling
                if (error.message === 'Wrong password.') {
                    setUsernameError('');
                    setPasswordError('Wrong password.');
                } else if (error.message === 'Wrong email.') {
                    setUsernameError('Wrong email.');
                    setPasswordError('');
                } else {
                    setPasswordError('User with given credentials does not exist.');
                }
            }
        }
    };

    return (
        <div className="container signinForm">
            <div className="form signin">
                <h2>Sign In</h2>
                <form> 
                <div className="inputBox">
                    <input
                        type="text"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="new-username"
                    />
                    <i>
                        <FontAwesomeIcon icon={faUser} />
                    </i>
                    <span>Username or Email</span>
                    <p className="error" style={{color: 'red'}}>{usernameError}</p> {/* Display username error */}
                </div>
                <div className="inputBox">
                    <input
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                    <i>
                        <FontAwesomeIcon icon={faLock} />
                    </i>
                    <span>Password</span>
                    <p className="error" style={{color: 'red'}}>{passwordError}</p> {/* Display password error */}
                </div>
                </form>
                <div className="inputBox">
                    <input type="submit" value="Login" onClick={handleLogin} />
                </div>
                <p>
                    Not Registered ?{' '}
                    <button type="button" className="create" onClick={props.onSignUpClick}>
                        Create an account
                    </button>
                </p>
            </div>
        </div>
    );
};

export default SignInForm;
