        import React from "react";


        const EditIcon = ({ onChange, color  }) => {

            return (
            <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onChange} // Handle click event
            style={{ cursor: "pointer", color: color  }} // Apply color based on state
            >
        <g clipPath="url(#clip0_567_4290)">
        <path d="M8.68969 20.2501H4.5C4.30109 20.2501 4.11032 20.1711 3.96967 20.0305C3.82902 19.8898 3.75 19.699 3.75 19.5001V15.3104C3.75009 15.1118 3.82899 14.9213 3.96938 14.7807L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.9212 7.54701 21.0001 7.7377 21.0001 7.93653C21.0001 8.13535 20.9212 8.32605 20.7806 8.46668L9.21937 20.0307C9.07883 20.1711 8.88834 20.25 8.68969 20.2501Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.75 6L18 11.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_567_4290">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        );
        };

        export default EditIcon;
