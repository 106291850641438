import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './CalendarComponentStyles.css'; // Import your custom styles

function CalendarComponent({ onDateChange, state, setState }) {

  const handleDateChange = (item) => {
    setState([item.selection]);
    if (onDateChange) {
      onDateChange(item.selection.startDate, item.selection.endDate);
    }
  };

  return (
    <DateRange
      editableDateInputs={false}
      onChange={handleDateChange}
      moveRangeOnFirstSelection={true}
      ranges={state}
      rangeColors={["rgb(0, 208, 189)"]}
      startDatePlaceholder="Start Date" 
      endDatePlaceholder="End Date" 
    />
  );
}

export default CalendarComponent;