import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../styles/AssetDetailsModal.css';
import { Box, Button, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import {  TextField, ThemeProvider, createTheme, makeStyles, useMediaQuery } from '@material-ui/core';
import EditIcon from '../assets/PencilSimple';
import axios from 'axios'; 
import EventCalendar from './EventCalendar';
import CustomTabPanel from './CustomTabPanel';
import Paper from '@mui/material/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { CustomAddCancelButton } from '../shared/buttons';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  '& .textField': {
    width: '100%',
    fontSize: '17px',
    fontFamily: 'Montserrat',
    backgroundColor: '#1A2033',
    borderRadius: '20px',
    marginLeft: '30px',
    color: '#FFF',
    padding: '10px 5px 5px 10px',
    '&:focus': {
      borderColor: 'rgb(0, 208, 189)',
    },
    '& .MuiInputBase-input': {
      color: '#FFF',
    },
    '& .MuiFormLabel-root': {
      color: '#FFF',
    },
    '& .MuiInput-underline:before': {
      borderColor: '#2b303b',
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgb(0, 208, 189)',
    },
    '&:hover .MuiInput-underline:before': {
      borderColor: '#2b303b',
    },
  },
}));

const customTheme = (outerTheme) => createTheme({
  palette: {
    mode: outerTheme.palette.mode,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#2b303b',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': 'rgb(0, 208, 189)',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgb(0, 208, 189)',
          borderRadius: '20px',
        },
        root: {
          '& .MuiOutlinedInput-input': {
            padding: '10px 10px 5px 10px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(0, 208, 189)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(0, 208, 189)',
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#fff', // Set label color to white
                '&.Mui-focused': {
                  color: '#fff', // Ensure the label color remains white when focused
                },
              },
            },
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  customFont: {
    fontFamily: 'Montserrat, sans-serif',
    color: '#FFFFFF',
    fontSize: '15px'
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tableView: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  clientCard: {
    border: '1px solid #ccc',
    margin: '10px 0',
    padding: '10px',
  },
  mobileOnlyIconButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none', // Hide on screens wider than the 'sm' breakpoint
    }
  },
    menuItem: {
        color: '#FFF',
      '&:hover': {
        color: '#00D0BD',
      },
    },
    underline: {
        '&:after': {
          borderBottom: '1px solid #00D0BD',
        },
        '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
          borderBottom: '1px solid #2b303b',
        },
        '&:before': {
          borderBottom: '1px solid #2b303b',
        },
      },
      button: {
        '& .MuiButton-label': {
          fontFamily: 'Montserrat',
          color: '#FFF',
          textTransform: 'capitalize',
        },
    },
  }));

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat',
    },
  });

const ContactDetailsModal = ({ isOpen, onClose, name, email, phone_number, contactNotes, type_id, contact_id, fileUrls, onContactUpdate, events, setEvents, deleteEvent, assets, setAssets, userId, setSelectedAsset, selectedAsset, notes, setNotes, setRefreshContactData}) => {

      const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [iconColor, setIconColor] = useState('#FFF');
    const [isEditing, setIsEditing] = useState(false);
    const [contactName, setContactName] = useState(name);
    const [contactEmail, setContactEmail] = useState(email);
    const [contactPhoneNumber, setContactPhoneNumber] = useState(phone_number);
    const[contactTypeId, setContactTypeId] = useState(type_id); // Add this line
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [eventTitle, setEventTitle] = useState('');
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [value, setValue] = React.useState(0);
// Assuming useEffect and useState are imported
const [cachedFileUrls, setCachedFileUrls] = useState([]);
const [fileNames, setFileNames] = useState([]);
const [selectedFiles, setSelectedFiles] = useState([]);
const [fileDetails, setFileDetails] = useState([]);
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
const [editedNotes, setEditedNotes] = useState(contactNotes || "");
const { t } = useTranslation();
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

// Adjusted fetchFileWithCache function to accept date and time
const fetchFileWithCache = async (fileUrl, cache, uploadDate, uploadTime) => {
  console.log("upload date:", uploadDate);
  console.log("upload time:", uploadTime);
  const baseUrl = fileUrl.split('?')[0];
  const filename = decodeURIComponent(baseUrl.split('/').pop());
  let response = await cache.match(baseUrl);
  if (response) {
    console.log(`Fetching ${baseUrl} from cache`);
    const blob = await response.blob();
    return {
      originalUrl: baseUrl,
      url: URL.createObjectURL(blob),
      filename,
      type: blob.type,
      date: uploadDate, // Use provided upload date
      time: uploadTime, // Use provided upload time
    };
  } else {
    console.log(`Fetching ${fileUrl} from network`);
    const fetchResponse = await fetch(fileUrl);
    if (fetchResponse.ok) {
      const blob = await fetchResponse.blob();
      await cache.put(baseUrl, new Response(blob));
      return {
        originalUrl: baseUrl,
        url: URL.createObjectURL(blob),
        filename,
        type: blob.type,
        date: uploadDate, // Use provided upload date
        time: uploadTime, // Use provided upload time
      };
    } else {
      throw new Error('Network response was not ok.');
    }
  }
};

// Adjusted fetchFiles function to pass upload date and time
useEffect(() => {
  const fetchFiles = async () => {
    let urls = [];
    if (Array.isArray(fileUrls)) {
urls = fileUrls.map(fileUrlObj => {
  // Parse lastModified into a Date object
  const dateTime = new Date(fileUrlObj.lastModified);
  
  // Add 2 hours
  dateTime.setHours(dateTime.getHours() + 2);
  
  // Format date and time
  const uploadDate = dateTime.toISOString().split('T')[0];
  const uploadTime = dateTime.toISOString().split('T')[1].split('.')[0]; // Remove milliseconds
  
  console.log("Processing URL:", fileUrlObj.doc_url, "Date:", uploadDate, "Time:", uploadTime);
  return {
    url: fileUrlObj.doc_url,
    date: uploadDate,
    time: uploadTime,
  };
});
    } else if (typeof fileUrls === 'string') {
      // Assuming there's a way to get the date and time for a single URL
      urls = [{ url: fileUrls, date: '2023-12-01', time: '12:26:13' }];
    }

    const cache = await caches.open('contact-files');
    const cachedFiles = await Promise.all(urls.map(async ({ url, date, time }) => {
      try {
        return await fetchFileWithCache(url, cache, date, time);
      } catch (error) {
        console.error(`Error fetching file: ${error}`);
        return { url: '', filename: 'Error', date: '', time: '', type: '' };
      }
    }));
    setCachedFileUrls(cachedFiles);
  };

  fetchFiles();
}, [fileUrls]);

// Function to handle file selection
const handleFileChange = (event) => {
  const newFiles = [...event.target.files];
  setSelectedFiles(prevFiles => {
    const existingFileNames = new Set(prevFiles.map(file => file.name));
    const uniqueNewFiles = newFiles.filter(file => !existingFileNames.has(file.name));
    // Update fileDetails state with just the names for now, other details will be added after upload
    setFileDetails(prevDetails => [...prevDetails, ...uniqueNewFiles.map(file => ({ name: file.name, date: '', time: '', type: '' }))]);
    return [...prevFiles, ...uniqueNewFiles];
  });
};

const uploadDocuments = async () => {
  if (!selectedFiles.length) return [];
  const uploadedUrls = [];

  for (const file of selectedFiles) {
    try {
      const formData = new FormData();
      const decodedFilename = decodeURIComponent(file.name);
      formData.append('file', new File([file], decodedFilename, { type: file.type }));

      const presignedResponse = await fetch(`${API_URL}/api/users/${userId}/uploadDocument`, {
        method: 'POST',
        body: formData,
      });
      const presignedData = await presignedResponse.json();
      await fetch(presignedData.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
      });

      // Use current date and time for upload timestamp
      const uploadDate = new Date();
      const timezoneOffset = uploadDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
      const localDate = new Date(uploadDate.getTime() - timezoneOffset);
      const formattedDate = localDate.toISOString().split('T')[0]; // YYYY-MM-DD
      const formattedTime = localDate.toISOString().split('T')[1].split('.')[0]; // HH:MM:SS

     // After successful upload, update fileDetails with the metadata
     setFileDetails(prevDetails => prevDetails.map(detail => {
      if (detail.name === decodedFilename) {
        return { ...detail, date: formattedDate, time: formattedTime, type: file.type };
      }
      return detail;
    }));
    
    uploadedUrls.push({
      accessUrl: presignedData.accessUrl,
      filename: decodedFilename,
      type: file.type,
      date: formattedDate,
      time: formattedTime,
    });
  } catch (error) {
    console.error('Error uploading document:', error);
  }
}

return uploadedUrls;
};

const handleDeleteFile = async (fileOriginalUrl) => {
  if (!contact_id) { 
    throw new Error("contact_id missing");
  }

  try {
    // Perform the DELETE request to the backend, including docUrl in the body
    const response = await fetch(`${API_URL}/api/deleteFile/${contact_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ docUrl: fileOriginalUrl }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete document from backend');
    }
    console.log('Document deleted successfully from backend');

    // Find the file object to revoke the URL and delete from cache
    const fileObject = cachedFileUrls.find(file => file.originalUrl === fileOriginalUrl);
    if (fileObject) {
      // Revoke the object URL to free up memory
      URL.revokeObjectURL(fileObject.url);

      // Remove the file from the cache using the original URL
      const cache = await caches.open('contact-files');
      await cache.delete(fileObject.originalUrl);
      console.log(`Cache delete successful for ${fileObject.originalUrl}`);
    } else {
      console.error("File object not found for URL revocation and cache deletion");
    }

    // Update the state to remove the file
    setCachedFileUrls(currentFiles => currentFiles.filter(file => file.originalUrl !== fileOriginalUrl));
  } catch (error) {
    console.error(error.message);
    // Handle the error appropriately
  }
};

const removeFile = (fileNameToRemove) => {
  // Filter out the file to remove based on its name
  setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileNameToRemove));

  // Update fileDetails by removing the file with the matching name
  setFileDetails(prevDetails => prevDetails.filter(detail => detail.name !== fileNameToRemove));
};
    
    const open = Boolean(anchorEl);

  // Callback function to add a new event
  const handleAddEvent = (event) => {
    // Create a new Date object from the start time
    const startDate = new Date(event.start);
  
    // Format the date and time
    const date = startDate.toISOString().split('T')[0]; // Get the date part
    const time = startDate.toTimeString().split(' ')[0]; // Get the time part
  
    // Create a new object with the property names expected by the backend
    const eventForBackend = {
      asset_id: selectedAsset ? selectedAsset.id : null, // Use selectedAsset.id if selectedAsset is defined
      title: event.title,
      date: date,
      time: time,
      contact_id: event.contact_id
    };
  
    fetch(`${API_URL}/api/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventForBackend),
    })
    .then(response => response.json())
    .then(data => {
      setEvents(prevEvents => [...prevEvents, data]);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    const handleEdit = () => {
        setIsEditing(true);
        setIconColor('#00D0BD');
      };
    
      const handleSave = async () => {
        setIconColor('#FFF');
        // After successfully updating the contact, upload documents
        const uploadedUrls = await uploadDocuments();
        console.log('Uploaded documents:', uploadedUrls);
      
        try {
          const response = await axios.put(`${API_URL}/api/contacts/${contact_id}`, { 
            name: contactName, 
            email: contactEmail, 
            phone_number: contactPhoneNumber,
            doc_urls: uploadedUrls,
          });
      
          if (response.status === 200) {
            console.log('Contact updated successfully:', response.data);
            console.log('Calling onContactUpdate with:', {
              contact_id,
              name: contactName,
              email: contactEmail,
              phone_number: contactPhoneNumber,
              type_id: contactTypeId, // Add this line
              doc_urls: uploadedUrls,
              contactNotes: editedNotes || null
            });
            onContactUpdate({
              contact_id,
              name: contactName,
              email: contactEmail,
              phone_number: contactPhoneNumber,
              doc_urls: uploadedUrls,
              contactNotes: editedNotes || null
            });
            // Merge existing and new document URLs
            const existingUrls = cachedFileUrls.map(file => file.url);
            const newCachedFileUrls = [...cachedFileUrls, ...uploadedUrls.filter(url => !existingUrls.includes(url.accessUrl)).map(url => ({
              url: url.accessUrl,
              filename: url.filename,
              date: url.date,
              time: url.time,
              type: url.type
            }))];
      
            setCachedFileUrls(newCachedFileUrls);
      
            setIsEditing(false);
            setRefreshContactData(true);
          } else {
            console.log('Failed to update contact:', response);
          }
        } catch (error) {
          console.error('Error updating contact:', error);
        }
      };
    
      const handleCancel = () => {
        setIconColor('#FFF');
        setContactName(name); // reset to original name
        setContactEmail(email); // reset to original email
        setContactPhoneNumber(phone_number); // reset to original phone number
        setIsEditing(false);
      };
      
  const modalRef = useRef(null);
  const timePickerRef = useRef(null);

  const handleCloseModal = useCallback((event) => {
    if (
      !isTimePickerOpen &&
      modalRef.current && 
      !modalRef.current.contains(event.target)
    ) {
      onClose();
    }
  }, [onClose, modalRef, isTimePickerOpen]);

  useEffect(() => {
    // Add event listener when modal is open
    if (isOpen) {
      document.addEventListener('mousedown', handleCloseModal);
    } else {
      document.removeEventListener('mousedown', handleCloseModal);
    }
  
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
    };
  }, [isOpen, handleCloseModal]);


  const modalClassName = isOpen ? "open" : "modal";

  if (!isOpen) return null;

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, ""); // remove all non-digit characters
    value = value.replace(/^(\d{3})(\d)/g, "$1-$2"); // add dash after first 3 digits
    value = value.replace(/-(\d{3})(\d)/, "-$1-$2"); // add dash after next 3 digits
    setContactPhoneNumber(value);
  };

  const handleMobileClose = () => {
    // Call the onClose function
    onClose();
  };
  return (
    <ThemeProvider theme={theme}>
        <Root>
    <div className={`modal-overlay ${modalClassName}`} onClick={handleCloseModal}>
    <div className="modal-content" ref={modalRef} onClick={(e) => e.stopPropagation()}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
      <IconButton
        className={classes.mobileOnlyIconButton}
    style={{ position: 'absolute', top: 22, left: 5, color: '#FFF', padding: '0px' }}
    onClick={handleMobileClose}
  >
    <CloseIcon />
  </IconButton>
        {isEditing ? (
                 <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px'}}>
          <TextField
            inputProps={{ 
              style: { color: '#FFF', fontWeight: 300, fontSize: '30px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat', marginTop: '10px' }
             }}
             InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
            value={contactName} onChange={(e) => setContactName(e.target.value)} 
          />
          </div>
        ) : (
          <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '30px', fontStyle: 'normal', marginLeft: '50px', paddingTop: '15px', fontFamily: 'Montserrat' }}>
            {contactName}
          </Typography>
        )}
        <IconButton
          style={{ cursor: 'pointer', paddingBottom: '30px' }}
          onClick={handleEdit}
        >
             <EditIcon color={iconColor} />
        </IconButton>
      </div>
       
        {isEditing ? (
  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
  <TextField 
    value={contactEmail} 
    onChange={(e) => setContactEmail(e.target.value)} 
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat' }
    }}
    InputProps={{
        classes: {
          underline: classes.underline,
        },
      }}
  />
  <TextField 
    value={contactPhoneNumber} 
   onChange={handlePhoneNumberChange} 
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat' },
    }}
    InputProps={{
        classes: {
          underline: classes.underline,
        },
      }}
  />

    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '20px', marginLeft: isSmallScreen ? '20px' : '50px'}}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor: 'rgb(0, 208, 189)'}}}>
    <Tab label={t("Documents/Files")}  {...a11yProps(0)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '170px'}} />
    <Tab label={t("Notes")} {...a11yProps(1)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '100px'}} />
  </Tabs>
</Box>
<CustomTabPanel value={value} index={0}  style={{marginLeft: isSmallScreen ? '0px' : '30px'}}>
  <div className="documentsContainer">
  <div className={classes.tableView}>
      <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
      <div style={{marginBottom: '10px', marginLeft: '700px'}}>
      <input
        accept="application/pdf" // Adjust based on your needs
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" style={{ color: '#FFF', backgroundColor: 'rgb(14, 21, 41)', textTransform: 'capitalize'}}>
         {t("Upload Files")}
        </Button>
      </label>
    </div>
 
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Name")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Date")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Time")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Type")}</Typography>
              </TableCell>
              <TableCell>
              <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Action")}</Typography>
            </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {fileDetails.map((fileDetail, index) => (
  <TableRow key={index}>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{fileDetail.name}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{fileDetail.date}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{fileDetail.time}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{fileDetail.type}</TableCell>
    <TableCell>
      <Button style={{textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'rgb(0, 208, 189)' }} onClick={() => removeFile(fileDetail.name)}>{t("Remove")}</Button>
    </TableCell>
  </TableRow>
))}
{cachedFileUrls.map((file, index) => (
  <TableRow key={`cached-${index}`}>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.filename}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.date}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.time}</TableCell>
    <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.type || 'Cached File'}</TableCell>
    <TableCell>
      <Button style={{textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'red' }} onClick={() => handleDeleteFile(file.originalUrl)}>{t("Delete")}</Button>
    </TableCell>
  </TableRow>
))}
          </TableBody>
        </Table>
        
      </TableContainer>
      </div>
      <div className={classes.mobileView}>
      <div style={{ marginLeft: '0px'}}>
      <input
        accept="application/pdf" // Adjust based on your needs
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" style={{ color: '#FFF', backgroundColor: 'rgb(14, 21, 41)', textTransform: 'capitalize'}}>
          {t("Upload Files")}
        </Button>
      </label>
    </div>
  {fileDetails.map((fileDetail, index) => (
    <div key={index} className={classes.clientCard}>
      <div className="fileItem" style={{ marginBottom: '10px', color: 'rgb(0, 208, 189)' }}>
        <Typography className={classes.customFont} style={{ fontWeight: 'bold' }}>{t("Name")}: {fileDetail.name}</Typography>
        <Typography className={classes.customFont}>{t("Date")}: {fileDetail.date}</Typography>
        <Typography className={classes.customFont}>{t("Time")}: {fileDetail.time}</Typography>
        <Typography className={classes.customFont}>{t("Type")}: {fileDetail.type}</Typography>
        <Button style={{ textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'red' }} onClick={() => removeFile(fileDetail.name)}>Remove</Button>
      </div>
    </div>
  ))}
  {cachedFileUrls.map((file, index) => (
    <div key={`cached-${index}`} className={classes.clientCard}>
      <div className="fileItem" style={{ marginBottom: '10px', color: 'rgb(0, 208, 189)' }}>
        <Typography className={classes.customFont} style={{ fontWeight: 'bold' }}>Name: {file.filename || `File ${index + 1}`}</Typography>
        <Typography className={classes.customFont}>{t("Date")}: {file.date}</Typography>
        <Typography className={classes.customFont}>{t("Time")}: {file.time}</Typography>
        <Typography className={classes.customFont}>{t("Type")}: {file.type || 'Cached File'}</Typography>
        <Button style={{ textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'red' }} onClick={() => handleDeleteFile(file.originalUrl)}>Delete</Button>
      </div>
    </div>
  ))}
</div>
  </div>
</CustomTabPanel>
<CustomTabPanel value={value} index={1}>
<div className="notesContainer">
    {isEditing ? (
       <TextField
       value={editedNotes}
       onChange={(e) => setEditedNotes(e.target.value)}
       className="textField"
       multiline
       sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color,
            borderRadius: '20px'
          },
          '&:hover fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color on hover
             borderRadius: '20px'
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color when focused
             borderRadius: '20px'
          },
        },
      }}
     />
    ) : (
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', paddingTop: '15px', paddingRight: '15px', fontFamily: 'Montserrat', marginLeft: '30px'}}>
        {contactNotes}
      </Typography>
    )}
  </div>
</CustomTabPanel>
 <div style={{ position: 'absolute', bottom: '0', width: '100%', display: 'flex', justifyContent: 'center', padding: '10px' }}>
    <CustomAddCancelButton  onClick={handleSave}>{t("Save")}</CustomAddCancelButton>
    <CustomAddCancelButton  onClick={handleCancel}>{t("Cancel")}</CustomAddCancelButton>
  </div>
</div>
        ) : (
<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '0px' }}>
  <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{contactEmail}</Typography>
  <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{contactPhoneNumber}</Typography>
  <EventCalendar contact_id={contact_id} events={events} onAddEvent={handleAddEvent} setEvents={setEvents} deleteEvent={deleteEvent} selectedDate={selectedDate} selectedTime={selectedTime} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime} eventTitle={eventTitle} setEventTitle={setEventTitle} assets={assets} setAssets={setAssets} userId={userId} setSelectedAsset={setSelectedAsset} selectedAsset={selectedAsset} notes={notes} setNotes={setNotes} timePickerRef={timePickerRef} isTimePickerOpen={isTimePickerOpen} setIsTimePickerOpen={setIsTimePickerOpen}/>
  <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: window.innerWidth < 768 ? '20px' : '50px', marginTop: '20px'}}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor: 'rgb(0, 208, 189)', width: value === 0 ? '170px' : '100px'}}}>
    <Tab label={t("Documents/Files")}  {...a11yProps(0)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '170px'}} />
    <Tab label={t("Notes")} {...a11yProps(1)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '100px'}} />
  </Tabs>
</Box>
<CustomTabPanel value={value} index={0}  style={{marginLeft: '30px'}}>
  <div className="documentsContainer">

      <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
      <div className={classes.tableView}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Name")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Date")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Time")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.customFont} style={{ color: 'white' }}>{t("Type")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cachedFileUrls.map((file, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <a href={file.url} target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(0, 208, 189)' }}>
                    {file.filename || `File ${index + 1}`}
                  </a>
                </TableCell>
                <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.date}</TableCell>
                <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.time}</TableCell>
                <TableCell style={{ color: 'rgb(0, 208, 189)' }}>{file.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      </TableContainer>
      <div className={classes.mobileView}>
  {cachedFileUrls.map((file, index) => (
    <div key={index} className={classes.clientCard}>
      <div className="fileItem" style={{marginBottom: '10px', color: 'rgb(0, 208, 189)'}}>
        <a href={file.url} target="_blank" rel="noopener noreferrer" style={{color: 'rgb(0, 208, 189)', fontWeight: 'bold'}}>
          Name: {file.filename || `File ${index + 1}`}
        </a>
        <Typography className={classes.customFont}>{t("Date")}: {file.date}</Typography>
        <Typography className={classes.customFont}>{t("Time")}: {file.time}</Typography>
        <Typography className={classes.customFont}>{t("Type")}: {file.type}</Typography>
      </div>
    </div>
  ))}
</div>
  </div>
</CustomTabPanel>
<CustomTabPanel value={value} index={1}>
<div className="notesContainer">
    {isEditing ? (
       <TextField
       value={editedNotes}
       onChange={(e) => setEditedNotes(e.target.value)}
       className="textField"
       multiline
       sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color,
            borderRadius: '20px'
          },
          '&:hover fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color on hover
             borderRadius: '20px'
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(0, 208, 189)', // Change border color when focused
             borderRadius: '20px'
          },
        },
      }}
     />
    ) : (
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', paddingTop: '15px', paddingRight: '15px', fontFamily: 'Montserrat', marginLeft: '50px'}}>
        {contactNotes}
      </Typography>
    )}
  </div>
</CustomTabPanel>
</div>

        )}
      </div>
    </div>
    </Root>
    </ThemeProvider>
  );
};

export default ContactDetailsModal;
