import React from 'react';

const FilePdf = ({ fill }) => {

  return (
<svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_605_6593)">
<path d="M42.1875 29.6875H35.9375V40.625" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M40.625 35.9375H35.9375" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.375 37.5H12.5C13.536 37.5 14.5296 37.0884 15.2621 36.3559C15.9947 35.6233 16.4062 34.6298 16.4062 33.5938C16.4062 32.5577 15.9947 31.5642 15.2621 30.8316C14.5296 30.0991 13.536 29.6875 12.5 29.6875H9.375V40.625" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21.875 29.6875V40.625H25C26.4504 40.625 27.8414 40.0488 28.867 39.0232C29.8926 37.9977 30.4688 36.6067 30.4688 35.1562C30.4688 33.7058 29.8926 32.3148 28.867 31.2893C27.8414 30.2637 26.4504 29.6875 25 29.6875H21.875Z" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.375 21.875V7.8125C9.375 7.3981 9.53962 7.00067 9.83265 6.70765C10.1257 6.41462 10.5231 6.25 10.9375 6.25H29.6875L40.625 17.1875V21.875" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29.6875 6.25V17.1875H40.625" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_605_6593">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default FilePdf;
