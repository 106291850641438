// MagnifyingGlassIcon.js
import React from "react";

const MagnifyingGlassIcon = () => (
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="MagnifyingGlass">
<path id="Vector" d="M41.309 38.7636L32.4531 29.9095C35.0199 26.8279 36.2999 22.8754 36.0267 18.8741C35.7535 14.8728 33.9482 11.1309 30.9864 8.42679C28.0246 5.72265 24.1343 4.26447 20.1247 4.35559C16.1152 4.44671 12.2951 6.08012 9.45924 8.91603C6.62334 11.7519 4.98993 15.572 4.8988 19.5815C4.80768 23.5911 6.26586 27.4814 8.97 30.4432C11.6741 33.405 15.4161 35.2103 19.4173 35.4835C23.4186 35.7566 27.3711 34.4767 30.4527 31.9099L39.3069 40.7658C39.4383 40.8973 39.5944 41.0016 39.7662 41.0727C39.9379 41.1439 40.122 41.1805 40.3079 41.1805C40.4939 41.1805 40.678 41.1439 40.8497 41.0727C41.0215 41.0016 41.1776 40.8973 41.309 40.7658C41.4405 40.6343 41.5448 40.4783 41.6159 40.3065C41.6871 40.1347 41.7237 39.9506 41.7237 39.7647C41.7237 39.5788 41.6871 39.3947 41.6159 39.2229C41.5448 39.0512 41.4405 38.8951 41.309 38.7636ZM7.76379 19.9552C7.76379 17.4366 8.51067 14.9744 9.90997 12.8802C11.3093 10.786 13.2982 9.15381 15.6251 8.18995C17.9521 7.22609 20.5126 6.97391 22.9829 7.46527C25.4531 7.95664 27.7223 9.1695 29.5032 10.9505C31.2842 12.7315 32.4971 15.0006 32.9884 17.4708C33.4798 19.9411 33.2276 22.5016 32.2638 24.8286C31.2999 27.1555 29.6677 29.1444 27.5735 30.5437C25.4793 31.943 23.0171 32.6899 20.4985 32.6899C17.1222 32.6862 13.8852 31.3433 11.4978 28.9559C9.11043 26.5685 7.76754 23.3315 7.76379 19.9552Z" fill="#00D0BD"/>
</g>
</svg>
);

export default MagnifyingGlassIcon;
