import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations.json';
import { I18nextProvider } from 'react-i18next';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { isSupported } from 'firebase/messaging';
import RequestNotificationPermissionModal from './RequestNotificationPermissionModal';
// Initialize i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false
    }
  });

// Add the Google Fonts link here
const googleFontsLink = document.createElement('link');
googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap';
googleFontsLink.rel = 'stylesheet';
document.head.appendChild(googleFontsLink);


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYRoEWb1Xet4eBZbsI1C5RgweKi9YM2AE",
  authDomain: "myapp-a4c10.firebaseapp.com",
  projectId: "myapp-a4c10",
  storageBucket: "myapp-a4c10.appspot.com",
  messagingSenderId: "17631405046",
  appId: "1:17631405046:web:6b361237a562a690ebddd7",
  measurementId: "G-9F2SJRGPSG"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();

// Correctly use onMessage
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // Check if Notification API is supported in the browser
  if ("Notification" in window) {
    // Request permission to show notifications
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        // Extract title and body from payload
        const { title, body } = payload.notification;
        // Display the notification
        new Notification(title, { body });
      }
    });
  }
});

// Check if Notification API is supported
if ("Notification" in window) {
  // Correctly request permission and get the token
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');

      // Ensure Firebase Messaging is supported before using it
      if (isSupported()) {
        const messaging = getMessaging();

        // Correctly use getToken with the messaging instance
        getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            // Send the token to your server and update the database
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });

        // Correctly use onMessage
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          // Display the notification
          new Notification(payload.notification.title, { body: payload.notification.body });
        });
      }
    }
  });
} else {
  console.log('Notification API not supported in this browser.');
}

// Service Worker Registration for Firebase Messaging
if ('serviceWorker' in navigator && isSupported()) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function(registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      console.log('ServiceWorker registration failed: ', err);
    }).catch(function(err) {
      console.log(err);
    });
  });
} else {
  console.log('Service Worker and/or Firebase Messaging is not supported by browser.');
}

const theme = createTheme({
  // Your theme customization
});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <I18nextProvider i18n={i18n}>
        <Suspense fallback="Loading...">
        <ThemeProvider theme={theme}>
          <App />
          </ThemeProvider>
        </Suspense>
      </I18nextProvider>
    </BrowserRouter>
    <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
  </React.StrictMode>,
  document.getElementById('root')
);