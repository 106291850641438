import React, { useState } from 'react';
import { toast } from 'react-toastify';
import '../styles/LoginForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { signup } from '../services/authService';

const SignUpForm = (props) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Reset errors when input changes
    if (name === 'username') setUsernameError('');
    if (name === 'email') setEmailError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match. Please try again.');
      return;
    }
    try {
      await signup(formData);
      toast.success('Congratulations! You have successfully created a new account.');
      props.onSignInClick();
    } catch (error) {
      console.error('Error signing up:', error);
      const errorMessage = error.message;
      if (errorMessage.includes('username')) {
        setUsernameError(`Username already taken. Try again.`);
      } else if (errorMessage.includes('email')) {
        setEmailError(`Email already taken. Try again.`);
      } else {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div className="container">
      <div className="form signup">
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputBox">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <i>
              <FontAwesomeIcon icon={faUser} />
            </i>
            <span>username</span>
          </div>
          {usernameError && <span className="error"  style={{color: 'red', fontSize: '13px'}}>{usernameError}</span>}
          <div className="inputBox">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <i>
              <FontAwesomeIcon icon={faEnvelope} />
            </i>
            <span>email address</span>
          </div>
          {emailError && <span className="error" style={{color: 'red', fontSize: '13px'}}>{emailError}</span>}
          <div className="inputBox">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <i>
              <FontAwesomeIcon icon={faLock} />
            </i>
            <span>create password</span>
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <i>
              <FontAwesomeIcon icon={faLock} />
            </i>
            <span>confirm password</span>
          </div>
          {passwordError && <span className="error"  style={{color: 'red', fontSize: '13px'}}>{passwordError}</span>}
          <div className="inputBox">
            <input type="submit" value="Create Account" />
          </div>
        </form>
        <p>
          Already a member ?{' '}
          <button type="button" className="login" onClick={props.onSignInClick}>
            Log in
          </button>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;