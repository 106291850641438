import React from 'react';

const Bell = ({ color }) => (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" fill="#1E1E1E"/>
<g clipPath="url(#clip0_0_1)">
<rect width="1280" height="828" transform="translate(-15 -175)" fill="#0E1529"/>
<mask id="path-1-inside-1_0_1" fill="white">
<path d="M-22 -176H38V652H-22V-176Z"/>
</mask>
<path d="M-22 -176H38V652H-22V-176Z" fill="#0E1529"/>
<path d="M37 -176V652H39V-176H37Z" fill="#333840" mask="url(#path-1-inside-1_0_1)"/>
<g clipPath="url(#clip1_0_1)">
<path d="M12 24C12 25.0609 12.4214 26.0783 13.1716 26.8284C13.9217 27.5786 14.9391 28 16 28C17.0609 28 18.0783 27.5786 18.8284 26.8284C19.5786 26.0783 20 25.0609 20 24" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.00009 13C7.00009 10.6131 7.9483 8.32387 9.63613 6.63604C11.324 4.94821 13.6131 4 16.0001 4C18.387 4 20.6762 4.94821 22.364 6.63604C24.0519 8.32387 25.0001 10.6131 25.0001 13C25.0001 17.4775 26.0376 21.075 26.8626 22.5C26.9502 22.6518 26.9964 22.8239 26.9966 22.9991C26.9967 23.1744 26.9508 23.3466 26.8635 23.4985C26.7761 23.6504 26.6504 23.7767 26.4989 23.8647C26.3473 23.9527 26.1753 23.9994 26.0001 24H6.00009C5.82506 23.9989 5.65338 23.952 5.5022 23.8638C5.35101 23.7756 5.22563 23.6492 5.13857 23.4974C5.05152 23.3456 5.00584 23.1735 5.0061 22.9985C5.00637 22.8235 5.05257 22.6516 5.14009 22.5C5.96384 21.075 7.00009 17.4763 7.00009 13Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="1280" height="828" fill="white" transform="translate(-15 -175)"/>
</clipPath>
<clipPath id="clip1_0_1">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
);
  
export default Bell;