import React from 'react';

const AmenitiesCheck = () => {

  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_744_10424)">
<path d="M3.125 11.25L7.5 15.625L17.5 5.625" stroke="#00D0BD" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_744_10424">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
  );
};
  
export default AmenitiesCheck;
