import React, { useState } from 'react';
import { Button, DialogTitle, DialogContent, List, ListItem, ListItemText, DialogActions, Dialog, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import i18n from 'i18next';
const AddFiltersModal = ({ isOpen, onClose, onAddFilter, restoredFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [newFilter, setNewFilter] = useState('');

  const handleAddNewFilter = () => {
    // Add validation if necessary
    onAddFilter(newFilter);
    setNewFilter('');
    onClose();
  };

  const handleRestoreFilter = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters((prevFilters) => prevFilters.filter((item) => item !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters((prevFilters) => [...prevFilters, filter]);
    }
  };

  const handleRestore = () => {
    // Pass an array of selected filters to onAddFilter
    onAddFilter(selectedFilters);
    setSelectedFilters([]); // Clear selected filters after restoration
    onClose();
    console.log('Restored filters:', selectedFilters);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        style={{
          position: 'fixed',
          top: 0,
          left: '60px',
          width: '250px',
          height: '100vh',
          flexShrink: 0,
          borderRight: '1px solid #333840',
          background: '#1A2033',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'auto',
          padding: '5px 10px'
        }}
      >
        <div>
          <DialogTitle style={{ color: 'white', textAlign: 'left', paddingLeft: '10px',  paddingBottom: '5px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>{i18n.t('Restore Filters')}</DialogTitle>
          <div style={{ borderTop: '1px solid gray', marginBottom: '10px' }}></div>
          {restoredFilters.length === 0 ? (
            <Typography variant="body1" style={{ color: 'white', textAlign: 'left', paddingLeft: '10px', fontWeight: 300, fontSize: '14px', fontFamily: 'Montserrat' }}>
              {i18n.t('There are no filters to be restored.')}
            </Typography>
          ) : (
            <List>
              {restoredFilters.map((filter) => (
                <ListItem key={filter} onClick={() => handleRestoreFilter(filter)} style={{ cursor: 'pointer' }}>
                  <ListItemText primary={filter} style={{ color: 'white' }} />
                  {selectedFilters.includes(filter) ? (
                    <RemoveCircleOutlineIcon style={{ color: 'gray' }} />
                  ) : (
                    <AddCircleOutlineIcon style={{ color: 'rgb(0, 208, 189)' }} />
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <DialogActions>
          {selectedFilters.length > 0 && (
            <Button
              onClick={handleRestore}
              style={{
                flexShrink: 0,
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                justifyContent: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
                maxWidth: '240px',
                marginLeft: '6px',
                color: 'white',
                fontFamily: 'Montserrat'
              }}
            >
              {i18n.t('Restore')}
            </Button>
          )}
          <Button
            onClick={onClose}
            style={{
              flexShrink: 0,
              borderRadius: '10px',
              border: '1px solid #1F2C41',
              background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
              maxWidth: '240px',
              marginLeft: '6px',
              color: 'white',
              fontFamily: 'Montserrat'
            }}
          >
           {i18n.t('Cancel')}
          </Button>
        </DialogActions>
        <div>
          <DialogTitle style={{ color: 'white', textAlign: 'left', paddingLeft: '10px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}> {i18n.t('Add New Filters')}</DialogTitle>
          <List>
            {/* {newFilter.map((filter) => (
              <ListItem key={filter}>
                <ListItemText primary={filter} />
                <Button onClick={() => onAddFilter(filter)}>Add</Button>
              </ListItem>
            ))} */}
          </List> 
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddFiltersModal;
