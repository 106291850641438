import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useTranslation } from "react-i18next";

const HamburgerMenu = ({ setSelectedMenuItem, selectedMenuItem , handleIconClick, permissions}) => {
  const [isMobile, setIsMobile] = useState(false);
  const menuRef = useRef(); 
  const { t } = useTranslation();

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleIconClick(''); // Close the hamburger menu
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

     // Render different content based on the device type
     const renderContent = () => {
      if (isMobile) {
  return (
   
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: '0px',
          width: '100%',
          height: 'calc(100% - 61px)',
          flexShrink: 0,
          borderRight: '1px solid #333840',
          background: '#1A2033',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '30px' }}>
        <Button onClick={() => { setSelectedMenuItem('assets'); handleIconClick(); }} style={{ color: selectedMenuItem === 'assets' ? 'rgb(0, 208, 189)' : '#FFFFFF',  textTransform: 'none', fontFamily: 'Montserrat', fontSize: '20px',  }} className="my-button">
          <ApartmentIcon style={{marginRight: '10px'}} />
          {t('Assets')}
        </Button>
        <Button onClick={() => { setSelectedMenuItem('contacts'); handleIconClick(); }}style={{color: selectedMenuItem === 'contacts' ? 'rgb(0, 208, 189)' : '#FFFFFF',  textTransform: 'none' , fontFamily: 'Montserrat', fontSize: '20px'}} className="my-button">
          <ContactPageIcon style={{marginRight: '10px'}}/>
          {t('Contacts')}
        </Button>
      </div>
      <Button
  onClick={() => { setSelectedMenuItem('calendar'); handleIconClick(); }}
  style={{
    color: selectedMenuItem === 'calendar' ? 'rgb(0, 208, 189)' : '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    fontSize: '20px'
  }}
  className="my-button"
>
  <CalendarTodayIcon style={{ marginRight: '10px' }} />
  {t('Calendar')}
</Button>
        </div>
  )
       } else {
    return (
   
      <div
      ref={menuRef}
        style={{
          position: 'fixed',
          top: 0,
          left: '60px',
          width: '250px',
          height: '100vh',
          flexShrink: 0,
          borderRight: '1px solid #333840',
          background: '#1A2033',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '30px' }}>
        <Button   onClick={() => setSelectedMenuItem('assets')} style={{ color: selectedMenuItem === 'assets' ? 'rgb(0, 208, 189)' : '#FFFFFF',  textTransform: 'none', fontFamily: 'Montserrat', fontSize: '20px', height: '47px', paddingLeft: '8px' }} className="my-button">
          <ApartmentIcon style={{marginRight: '10px'}} />
        {t('Assets')}
        </Button>
        <Button  onClick={() => setSelectedMenuItem('contacts')} style={{color: selectedMenuItem === 'contacts' ? 'rgb(0, 208, 189)' : '#FFFFFF',  textTransform: 'none' , fontFamily: 'Montserrat', fontSize: '20px', height: '47px', paddingLeft: '8px'}} className="my-button">
          <ContactPageIcon style={{marginRight: '10px'}}/>
        {t('Contacts')}
        </Button>
     
      <Button
  onClick={() => setSelectedMenuItem('calendar')}
  style={{
    color: selectedMenuItem === 'calendar' ? 'rgb(0, 208, 189)' : '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    height: '47px', 
    paddingLeft: '8px'
  }}
  className="my-button"
>
  <CalendarTodayIcon style={{ marginRight: '10px' }} />
  {t('Calendar')}
</Button>
{(permissions || []).includes('finance_access') && (
  <Button onClick={() => setSelectedMenuItem('finance')} style={{color: selectedMenuItem === 'finance' ? 'rgb(0, 208, 189)' : '#FFFFFF', textTransform: 'none', fontFamily: 'Montserrat', fontSize: '20px', height: '47px', paddingLeft: '8px'}} className="my-button">
    <CalculateIcon style={{marginRight: '10px'}}/>
    {t('Finance')}
  </Button>
)}
 </div>
        </div>
  );
  }
};
return (
  <React.Fragment>
    {renderContent()}
  </React.Fragment>
)
};
export default HamburgerMenu;
