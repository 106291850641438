import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button'; // Import Button from @mui/material
import '../styles/SearchBar.css';

function LanguageSwitcher({setAnchorEl, setShowLanguageSwitcher}) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(false);
    setShowLanguageSwitcher(false);
  };

  return (
    <div>
      <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontFamily: 'Montserrat'}}  className="my-button" onClick={() => changeLanguage('en-US')}>{t('English')}</Button>
      <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontFamily: 'Montserrat'}}  className="my-button" onClick={() => changeLanguage('sr')}>{t('Serbian')}</Button>
    </div>
  );
}

export default LanguageSwitcher;