import React, { useEffect, useState } from 'react';
import ContactsSearchBar from './ContactsSearchBar';
import { Button, IconButton, List, ListItem, ListItemText,Popover, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../styles/ContactsContent.css';
import axios from 'axios';
import CheckCircle from '../assets/CheckCircle';
import UncheckCircle from '../assets/UncheckCircle';
import CustomCheckBox from '../assets/CustomCheckBox';
import UncheckAssets from '../assets/UncheckAssets';
import CheckedAssets from '../assets/CheckedAssets';
import ContactDetailsModal from './ContactDetailsModal';
import { useTranslation } from "react-i18next";

const ContactsContent = ({userId, contactsData, setContactsData, selectedContacts, setSelectedContacts, showSelectedContactsOnly, setShowSelectedContactsOnly, onToogleContactActions, showContactActions, assets, setAssets, setSelectedAsset, selectedAsset, notes, setNotes }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isAllContactsSelected, setIsAllContactsSelected] = useState(false); // New state to track if all contacts are selected
    const [selectedContact, setSelectedContact] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [refreshContactData, setRefreshContactData] = useState(false);
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      // Add events state
// In the parent component
const [events, setEvents] = useState([]);
// Fetch events from API instead of local storage
useEffect(() => {
  if (selectedContact && !isNaN(selectedContact.contact_id)) {
    fetch(`${API_URL}/api/events/${selectedContact.contact_id}`)
      .then(response => response.json())
      .then(data => {
        setEvents(data);
        console.log(data); // Log the data
      })
      .catch(error => console.error('Error:', error));
  }
}, [selectedContact]);



  
    useEffect(() => {
      // Detect if the user is on a mobile device
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('android') || userAgent.includes('iphone')) {
        setIsMobile(true);
      }
    }, []);

    useEffect(() => {
      if (refreshContactData) {
        axios.get(`${API_URL}/api/contacts/${userId}`) // replace `userId` with the actual userId
          .then(response => {
            setContactsData(response.data);
            setRefreshContactData(false);
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }, [userId, refreshContactData]);

    useEffect(() => {
        axios.get(`${API_URL}/api/contacts/${userId}`) // replace `userId` with the actual userId
            .then(response => {
                setContactsData(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [userId]);

    // Function to handle click on a grid item
    const handleContactClick = (contact) => {
        setSelectedContact(contact); // Set the selected asset when clicked
      };

    const [contactsCountMessage, setContactsCountMessage] = useState('');
    const [selectedSortOption, setSelectedSortOption] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    // pass a callback function from ContactsContent to ContactDetailsModal that gets called when a contact is updated.
    const handleContactUpdate = (updatedContact) => {
        setContactsData(contactsData.map(contact =>
          contact.contact_id === updatedContact.contact_id ? updatedContact : contact
        ));
      };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
        if (term === '') {
          setContactsCountMessage(t('All contacts'));
        } else {
            const filteredContacts = contactsData.filter(contact =>
                (contact.name && contact.name.toLowerCase().includes(term.toLowerCase())) ||
                (contact.email && contact.email.toLowerCase().includes(term.toLowerCase())) ||
                (contact.phone && contact.phone.toLowerCase().includes(term.toLowerCase()))
            );
            setContactsCountMessage(`${filteredContacts.length} ${filteredContacts.length === 1 ? t('contact found') : t('contacts found')}`);
        }
    };

        // Filter contacts based on the search term
        const filteredContacts = contactsData.filter(contact =>
            (contact.name && contact.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (contact.phone && contact.phone.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        const open = Boolean(anchorEl);
        const id = open ? 'sort-by-popover' : undefined;

                    // Closing the popover
            const handleClosePopover = () => {
                setAnchorEl(null);
            };

            // Function to handle sort button click and set the anchor element for popover
            const handleSortButtonClick = (event) => {
                setAnchorEl(event.currentTarget);
            };

            const handleSortOptionClick = (sortOption) => {
                let sortedData = [];
                      // Sorting data based on the selected sort option
                switch (sortOption) {
                  case 'Most Recent':
                    sortedData = [...contactsData].sort((a, b) => b.contact_id - a.contact_id);
                    break;
                  case 'Oldest First':
                    sortedData = [...contactsData].sort((a, b) => a.contact_id - b.contact_id);
                    break;
                  case 'A-Z':
                    sortedData = [...contactsData].sort((a, b) => a.name.localeCompare(b.name));
                    break;
                  case 'Z-A':
                    sortedData = [...contactsData].sort((a, b) => b.name.localeCompare(a.name));
                    break;
                  default:
                    sortedData = [...contactsData];
                }
                  // Sorting logic remains the same
                  setContactsData(sortedData);
                setSelectedSortOption(sortOption); // Update the selected sort option
                setAnchorEl(null);
              };

               // Render the button text based on selection state
               const renderButtonText = () => {
                if (selectedContacts.length === 0) {
                  return (
                    <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center', }}>
                       {t('Select all')} <CheckCircle style={{ marginLeft: '10px' }} />
                      </span>
                  );
                } else if (isAllContactsSelected) {
                  return (
                    <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center' }}>
                     {t('Deselect all')}  <UncheckCircle style={{ marginLeft: '5px' }}/> 
                      </span>
                  );
                } else {
                  return (
                    <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center' }}>
                      {t('Select all')} <CheckCircle  style={{ marginLeft: '10px' }}/>
                    </span>
                  );
                }
              };
      const handleCheckboxChange = (contact, isChecked, isSelectAllChecked) => {
        // Logic to update selectedAssets state based on checkbox change
        if (isChecked) {
                setSelectedContacts([...selectedContacts, contact]);
        } else if (isSelectAllChecked) {
          if (!isAllContactsSelected) {
            setSelectedContacts([...filteredContacts]); 
            console.log("all selected assets:" , selectedContacts)
            setIsAllContactsSelected(true);
          } else {
            setSelectedContacts([]);
            setIsAllContactsSelected(false);
            console.log("all  assets deselected:" , selectedContacts)
          }
        } else {

            setIsAllContactsSelected(false); 
            setSelectedContacts(selectedContacts.filter(c => c.contact_id !== contact.contact_id));
          console.log("all  assets deselected:" , selectedContacts)

        }
      };
      useEffect(() => {
        console.log("selectedContacts:", selectedContacts);
      }, [selectedContacts]);

      const handleSelectAllContacts = () => {  
        handleCheckboxChange(null, false, true); 
      };


        // Handle checkbox change
        const handleChange = (contact) => {
            const newIsChecked = !selectedContacts.some(c => c.contact_id === contact.contact_id); // Toggle isChecked state
          
            if (newIsChecked) {
              console.log('Selected contact:', contact);
            } else {
              console.log('Deselected contact:', contact);
            }
          
            handleCheckboxChange(contact, newIsChecked, false); // Pass the updated state to the parent component
          };

          const [isUnselectAllInProgress, setIsUnselectAllInProgress] = useState(false);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          useEffect(() => {
            // This code will run whenever showSelectedOnly changes
          }, [showSelectedContactsOnly]);
          
          const renderButtonIcon = () => {
            if (showContactActions) {
              return <UncheckAssets  onClick={handleUnselectAll}/>;
            } else {
              return selectedContacts.length > 0 ? <CheckedAssets /> : null;
            }
          };
          useEffect(() => {
            if (isUnselectAllInProgress) {
                setShowSelectedContactsOnly(false);
              setIsUnselectAllInProgress(false);
            }
          }, [isUnselectAllInProgress]);

          const handleUnselectAll = () => {
            console.log('filteredData before unselecting all:', contactsData);
            setSelectedContacts([]);
            setIsAllContactsSelected(false);
            setIsUnselectAllInProgress(true);
            console.log('filteredData after unselecting all:', contactsData);
          };
        
          if (isMobile) {
        return (
            <>  
                <ContactsSearchBar updateSearchTerm={handleSearchChange} contactsCountMessage={contactsCountMessage}/>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 1px)', width: '920px', paddingTop: '52px', justifyContent: 'flex-start', position: 'fixed', top: '100px', left: '52%', transform: 'translateX(-50%)' }}>
                <Button
            onClick={handleSortButtonClick}
            style={{
              color: "#FFFFFF",
              flexShrink: 0,
              maxWidth: '225px',
              width: '50%',
              height: '40px',
              borderRadius: '10px',
              border: '1px solid #1F2C41',
              background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: '10px',
              marginLeft: '15px'
            }}
          >
            <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
              {selectedSortOption ? t(selectedSortOption) : t('Sort By')}
            </Typography>
            <KeyboardArrowDownIcon />
          </Button>
  
            <IconButton variant="h6" onClick={() => handleSelectAllContacts()}  style={{  color: '#FFFFFF', cursor: 'pointer', zIndex: '1000', marginRight: '30px', marginTop: '15px', justifyContent: 'flex-end',fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px',paddingTop: '5px', textTransform: 'capitalize', width: '160px' }}>
          {renderButtonText()}
            </IconButton>
           
          </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '225px', }}>
            <Button onClick={() => handleSortOptionClick('Most Recent')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('Most Recent')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Oldest First')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('Oldest First')}
            </Button>
            <Button onClick={() => handleSortOptionClick('A-Z')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('A-Z')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Z-A')} style={{ color: '#FFFFFF', textTransform: 'capitalize'}} className="my-button">
            {t('Z-A')}
            </Button>
            </List>
    
            </Popover>
            <div className="scrollbar4" style={{ maxWidth: 'calc(100% - 24px)', position: 'fixed', marginLeft: '2%',   height: 'calc(100% - 270px)', overflow: 'scroll', overflowX: 'hidden',  top: '210px', left: '5%', right: '5%' }}>
   
            {(showSelectedContactsOnly ? selectedContacts : filteredContacts).map((contact, index) => (
  <ListItem key={index} style={{ borderRadius: '10px', border: '1px solid #1F2C41',  marginBottom: '5px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
    <IconButton onClick={() => handleChange(contact)}style={{ position: 'absolute', top: 0, right: 0 }} >
      <CustomCheckBox isChecked={selectedContacts.some(c => c.contact_id === contact.contact_id)} handleChange={() => handleCheckboxChange(contact, !selectedContacts.some(c => c.contact_id === contact.contact_id))} />
    </IconButton>
    <ListItemText primary={contact.name} style={{ color: '#00D0BD', fontWeight: 500 , paddingRight: '10px', maxWidth: '200px'}} onClick={() => handleContactClick(contact)}/>
    <ListItemText primary={contact.email} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} onClick={() => handleContactClick(contact)}/>
    <ListItemText primary={contact.phone_number} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} onClick={() => handleContactClick(contact)}/>
  </ListItem>
))}
        </div>
                      {/* Conditionally render the button based on selectedAssets count */}
                      {selectedContacts.length > 0 && (
        <div style={{
          position: 'fixed',
          bottom: '65px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000
        }}>
      <Button
        variant="contained"
        onClick={onToogleContactActions}
        startIcon={renderButtonIcon()}
        style={{
          width: '340px',
          textTransform: 'capitalize',
          color: '#FFF',
          backgroundColor: 'rgb(26, 32, 51)', // Set the background color
          fontFamily: 'Montserrat',
          boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
          transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
          fontSize: '18px',
          borderRadius: '20px'
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
          e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          e.currentTarget.style.color = 'white'; // Restore text color on mouse out
        }}
      >

{selectedContacts.length === contactsData.length
        ? t('AllContactsSelected', { count: selectedContacts.length })
        : selectedContacts.length === 1
          ? t('OneContactSelected', { count: selectedContacts.length })
          : t('MultipleContactsSelected', { count: selectedContacts.length })}
      </Button>
        </div>
      )}
            {selectedContact && (
        <div>
          {/* Add this line for debugging */}
          {console.log(selectedContact)}
          <ContactDetailsModal isOpen={true} onClose={() => setSelectedContact(null)} name={selectedContact.name} email = {selectedContact.email} phone_number = {selectedContact.phone_number} type_id={selectedContact.type_id} contact_id = {selectedContact.contact_id} fileUrls = {selectedContact.doc_urls}  onContactUpdate={handleContactUpdate}   events={events} setEvents={setEvents} assets={assets} setAssets={setAssets} userId={userId} setSelectedAsset={setSelectedAsset} selectedAsset={selectedAsset} notes={notes}  contactNotes = {selectedContact.contactNotes} setNotes={setNotes} setRefreshContactData={setRefreshContactData}/>
        </div>
      )}
            </>
        )
             } else {
              return (
                <>  
                    <ContactsSearchBar updateSearchTerm={handleSearchChange} contactsCountMessage={contactsCountMessage}/>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 140px)', width: '920px', paddingTop: '20px', justifyContent: 'flex-end', position: 'fixed', top: '100px', 
                  transform: 'translateX(-50%)',}}>
                    <Button
                  onClick={handleSortButtonClick}
                  style={{
                    color: "#FFFFFF",
                    flexShrink: 0,
                    maxWidth: '225px',
                    width: '50%',
                    height: '40px',
                    borderRadius: '10px',
                    border: '1px solid #1F2C41',
                    background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginLeft: '20px',
                  }}
                >
              <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
              {selectedSortOption ? t(selectedSortOption) : t('Sort By')}
              </Typography>
                  <KeyboardArrowDownIcon />
                </Button>
              </div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '225px', }}>
                <Button onClick={() => handleSortOptionClick('Most Recent')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('Most Recent')}
                </Button>
                <Button onClick={() => handleSortOptionClick('Oldest First')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('Oldest First')}
                </Button>
                <Button onClick={() => handleSortOptionClick('A-Z')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('A-Z')}
                </Button>
                <Button onClick={() => handleSortOptionClick('Z-A')} style={{ color: '#FFFFFF', textTransform: 'capitalize'}} className="my-button">
                {t('Z-A')}
                </Button>
                </List>
        
                </Popover>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 120px)', width: '920px', marginTop: '52px', justifyContent: 'flex-end', position: 'fixed', top: '100px', left: '52%', 
                  transform: 'translateX(-50%)' }}>
                <IconButton variant="h6" onClick={() => handleSelectAllContacts()}  style={{  color: '#FFFFFF', cursor: 'pointer', zIndex: '1000', marginRight: '30px', marginTop: '25px', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px',paddingTop: '5px', textTransform: 'capitalize', width: '160px' }}>
              {renderButtonText()}
                </IconButton>
                </div>
                <div className="scrollbar4" style={{ maxWidth: 'calc(100% - 140px)', position: 'fixed', width: '920px', transform: 'translateX(-50%)', marginLeft: '2%', maxHeight: '555px', overflow: 'scroll', overflowX: 'hidden',  top: '210px', }}>
                {filteredContacts.length === 0 ? (
    contactsData.length === 0 ? (
      <Typography variant="body1" style={{ color: '#FFFFFF' }}>
        {t('You have no contacts.')}
      </Typography>
    ) : (
      <Typography variant="body1" style={{ color: '#FFFFFF' }}>
        {t('No data matches your search.')}
      </Typography>
    )
  ) : (
    (showSelectedContactsOnly ? selectedContacts : filteredContacts).map((contact, index) => (
      <ListItem key={index} style={{ borderRadius: '10px', border: '1px solid #1F2C41',  marginBottom: '5px', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: '880px'}}>
        <IconButton onClick={() => handleChange(contact)}style={{ position: 'absolute', top: 0, right: 0 }} >
          <CustomCheckBox isChecked={selectedContacts.some(c => c.contact_id === contact.contact_id)} handleChange={() => handleCheckboxChange(contact, !selectedContacts.some(c => c.contact_id === contact.contact_id))} />
        </IconButton>
        <ListItemText primary={contact.name} style={{ color: '#00D0BD', fontWeight: 500 , paddingRight: '10px', maxWidth: '200px'}} onClick={() => handleContactClick(contact)}/>
        <ListItemText primary={contact.email} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} onClick={() => handleContactClick(contact)}/>
        <ListItemText primary={contact.phone_number} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} onClick={() => handleContactClick(contact)}/>
      </ListItem>
    ))
  )}
</div>
                          {/* Conditionally render the button based on selectedAssets count */}
                          {selectedContacts.length > 0 && (
            <div style={{
              position: 'fixed',
              bottom: '15px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000
            }}>
          <Button
            variant="contained"
            onClick={onToogleContactActions}
            startIcon={renderButtonIcon()}
            style={{
              textTransform: 'capitalize',
              color: '#FFF',
              backgroundColor: 'rgb(26, 32, 51)', // Set the background color
              fontFamily: 'Montserrat',
              boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
              transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
              fontSize: '18px',
              borderRadius: '20px'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
              e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
              e.currentTarget.style.color = 'white'; // Restore text color on mouse out
            }}
          >
    
    {selectedContacts.length === contactsData.length
        ? t('AllContactsSelected', { count: selectedContacts.length })
        : selectedContacts.length === 1
          ? t('OneContactSelected', { count: selectedContacts.length })
          : t('MultipleContactsSelected', { count: selectedContacts.length })}
          </Button>
            </div>
          )}
                {selectedContact && (
            <div>
              <ContactDetailsModal isOpen={true} onClose={() => setSelectedContact(null)} name={selectedContact.name} email = {selectedContact.email} phone_number = {selectedContact.phone_number} type_id={selectedContact.type_id} contact_id = {selectedContact.contact_id} fileUrls = {selectedContact.doc_urls}  onContactUpdate={handleContactUpdate}   events={events} setEvents={setEvents} assets={assets} setAssets={setAssets} userId={userId} setSelectedAsset={setSelectedAsset} selectedAsset={selectedAsset} notes={notes} setNotes={setNotes} contactNotes = {selectedContact.contactNotes} setRefreshContactData={setRefreshContactData}/>
            </div>
          )}
                </>
            )

        }
    };

export default ContactsContent;