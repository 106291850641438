import React, { useEffect, useState } from 'react';
import FinanceSearchBar from './FinanceSearchBar';
import { AppBar, Tabs, Tab, Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const FinanceContent = ({ userId }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [financeCountMessage, setFinanceCountMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [assetsData, setAssetsData] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
        if (newIndex === 1) {
            filterAssets(assetsData, 'Sold');
        } else if (newIndex === 2) {
            filterAssets(assetsData, 'Leased');
        } else {
            setFilteredAssets([]); // Empty table for Overview
        }
    };

    useEffect(() => {
        // Fetch assets data from API or database
        fetch(`${API_URL}/api/users/${userId}/assets`)
            .then(response => response.json())
            .then(data => {
                setAssetsData(data);
                setFilteredAssets([]); // Set to empty array for Overview by default
            });
    }, [userId, API_URL]);


    const filterAssets = (data, status) => {
        const filtered = data.filter(asset => asset.status === status)
            .map(asset => ({
                name: asset.name,
                price: asset.price,
                commission: status === 'Leased' ? '50.00' : asset.commission,
                agentCommission: asset.agentCommission,
                profit: status === 'Leased' ? calculateLeasedProfit(asset.price) : calculateProfit(asset.price, asset.commission, asset.agentCommission)
            }));
        setFilteredAssets(filtered);
    };
    
    const calculateProfit = (price, commission, agentCommission) => {
        const totalCommissionPercentage = commission - (agentCommission || 0);
        return price * (totalCommissionPercentage / 100);
    };

    const calculateLeasedProfit = (price) => {
        return price * 0.5;
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        // Detect if the user is on a mobile device
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('android') || userAgent.includes('iphone')) {
            setIsMobile(true);
        }
    }, []);

    if (isMobile) {
        return (
            <>
                <FinanceSearchBar updateSearchTerm={handleSearchChange} financeCountMessage={financeCountMessage} />
            </>
        );
    } else {
        return (
                        <>
            <FinanceSearchBar updateSearchTerm={handleSearchChange} financeCountMessage={financeCountMessage} />
            <AppBar position="fixed" style={{ top: '116px', zIndex: 98, width: '900px', maxWidth: 'calc(100% - 120px)', transform: 'translateX(-50%)', backgroundColor: 'transparent', left: '52%' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: 'rgb(0, 208, 189)' } }}>
                    <Tab label={t("Overview")} />
                    <Tab label={t("Sold")}  />
                    <Tab label={t("Leased")} />
                </Tabs>
            </AppBar>
            <Box p={3} sx={{position: 'fixed', width: '920px', top: '176px', maxWidth: 'calc(100% - 120px)', transform: 'translateX(-50%)', left: '52%'}}>
                {tabIndex !== 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={{backgroundColor:'rgb(74, 79, 94)'}}>
                                    <TableCell style={{color: 'white'}}>{t("Asset Name")}</TableCell>
                                    <TableCell style={{color: 'white'}}>{t("Cost")}</TableCell>
                                    <TableCell style={{color: 'white'}}>{t("Commission")}</TableCell>
                                    <TableCell style={{color: 'white'}}>{t("Agent Commission")}</TableCell>
                                    <TableCell style={{color: 'white'}}>{t("Profit")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredAssets.map((row, index) => (
                                    <TableRow key={index} style={{backgroundColor: '#1C2138 '}}>
                                        <TableCell style={{color: 'white'}}>{row.name}</TableCell>
                                        <TableCell style={{color: 'white'}}>€{row.price}</TableCell>
                                        <TableCell style={{color: 'white'}}>{row.commission}%</TableCell>
                                        <TableCell style={{color: 'white'}}>{row.agentCommission !== undefined ? row.agentCommission : 0}%</TableCell>
                                        <TableCell style={{color: 'white'}}>€{(row.profit !== undefined ? row.profit.toFixed(2) : '0.00')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h6" style={{ 
                        color: '#FFF', 
                        fontWeight: 500, 
                        fontSize: '16px', 
                        fontStyle: 'normal',
                        width: '200px', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'Montserrat'
                      }}>{t("Agent Performance")}</Typography>
                )}
            </Box>

            </>
        );
    }
};

export default FinanceContent;