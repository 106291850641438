import React from 'react';

const AddNewSelectionIcon = ({ marginRight }) => {

  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: marginRight}}>
<g clipPath="url(#clip0_668_6516)">
<path d="M3.125 10H16.875" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 3.125V16.875" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_668_6516">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default AddNewSelectionIcon;
