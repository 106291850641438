import React, { useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import '../styles/AssetDetailsModal.css';
import { Box, IconButton, Tab, Tabs, Typography, Checkbox, MenuItem, Select, TextField, createTheme, ThemeProvider, useTheme} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AmenitiesCheck from '../assets/AmenitiesCheck';
import EditIcon from '../assets/PencilSimple';
import CircleIcon from '@mui/icons-material/Circle';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import CustomTabPanel from './CustomTabPanel';
import ClientsTab from './ClientsTab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import sampleimage from '../img/sampleimage.png';
import { CustomAddCancelButton } from '../shared/buttons';
import CalendarComponent from './CalendarComponent';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const PREFIX = 'AssetDetailsModal';

const classes = {
  root: `${PREFIX}-root`,
  menuItem: `${PREFIX}-menuItem`,
  underline: `${PREFIX}-underline`,
  button: `${PREFIX}-button`,
  select: `${PREFIX}-select`,
  icon: `${PREFIX}-icon`,
  paper: `${PREFIX}-paper`,
  paperTwo: `${PREFIX}-paperTwo`,
  itemTwo: `${PREFIX}-itemTwo`,
  item: `${PREFIX}-item`,
  hoveredItem: `${PREFIX}-hoveredItem`,
  scrollableDiv: `${PREFIX}-scrollableDiv`,
  scrollableDivMobile: `${PREFIX}-scrollableDivMobile`,
  textField: `${PREFIX}-textField`
};
const menuItemStyle = {
  color: '#FFF',
  backgroundColor: '#2b303b',
  display: 'flex',
  flexDirection: 'column',
};

const hoveredMenuItemStyle = {
  color: 'rgb(0, 208, 189)',
  backgroundColor: '#2b303b',
};


const Root = styled('div')(({ theme }) => ({
  [`& .${classes.menuItem}`]: {
    color: '#FFF',
    backgroundColor: '#2b303b',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      color: '#00D0BD',
      backgroundColor: '#2b303b',
    },
  },
  [`& .${classes.underline}`]: {
    '&:after': {
      borderBottom: '1px solid #00D0BD',
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      borderBottom: '1px solid #2b303b',
    },
    '&:before': {
      borderBottom: '1px solid #2b303b',
    },
  },
  [`& .${classes.button}`]: {
    '& .MuiButton-label': {
      fontFamily: 'Montserrat',
      color: '#FFF',
      textTransform: 'capitalize',
    },
  },
  [`& .${classes.select}`]: {
    color: '#FFF',
    fontWeight: 300,
    fontSize: '18px',
    marginLeft: '20px',
    fontFamily: 'Montserrat',
    '&:before': {
      borderBottom: '1px solid #2b303b',
    },
    '&:after': {
      borderBottom: '1px solid #2b303b',
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      borderBottom: '1px solid #2b303b',
    },
    '& .MuiInputBase-input': {
      color: '#fff',
      fontFamily: 'Montserrat',
    },
    '& .MuiSvgIcon-root': {
      color: '#FFF',
    },
  },
  [`& .${classes.icon}`]: {
    fill: '#FFF',
  },
  [`& .${classes.paper}`]: {
    backgroundColor: '#2b303b',
    maxHeight: 120,
    overflow: 'auto',
    overflowX: 'hidden',
    color: '#FFF',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A4F5E',
      borderRadius: '15px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#4A4F5E',
      boxShadow: 'inset 0 0 5px grey',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.paperTwo}`]: {
    backgroundColor: '#2b303b',
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.itemTwo}`]: {
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2b303b',
  },
  [`& .${classes.item}`]: {
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2b303b',
  },
  [`& .${classes.hoveredItem}`]: {
    color: 'rgb(0, 208, 189)',
  },
  [`& .${classes.scrollableDiv}`]: {
    maxHeight: '160px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A4F5E',
      borderRadius: '15px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#4A4F5E',
      boxShadow: 'inset 0 0 5px grey',
    },
  },
  [`& .${classes.scrollableDivMobile}`]: {
    maxHeight: '150px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A4F5E',
      borderRadius: '15px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#4A4F5E',
      boxShadow: 'inset 0 0 5px grey',
    },
  },
  [`& .${classes.textField}`]: {
    width: '100%',
    fontSize: '17px',
    fontFamily: 'Montserrat',
    backgroundColor: '#1A2033',
    borderRadius: '20px',
    color: '#FFF',
    padding: '10px 5px 5px 10px',
    '&:focus': {
      borderColor: 'rgb(0, 208, 189)',
    },
    '& .MuiInputBase-input': {
      color: '#FFF',
    },
    '& .MuiFormLabel-root': {
      color: '#FFF',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
}));

const customTheme = (outerTheme) => createTheme({
  palette: {
    mode: outerTheme.palette.mode,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#2b303b',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': 'rgb(0, 208, 189)',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgb(0, 208, 189)',
          borderRadius: '20px',
        },
        root: {
          '& .MuiOutlinedInput-input': {
            padding: '10px 10px 5px 10px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(0, 208, 189)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(0, 208, 189)',
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#fff', // Set label color to white
                '&.Mui-focused': {
                  color: '#fff', // Ensure the label color remains white when focused
                },
              },
            },
          },
        },
      },
    },
  },
});

const CustomTextField = TextField;

const theme = createTheme({
  components: { 
    // Step 2: Override the MuiInput styles (or directly target MuiSelect if needed)
    MuiInput: {
      styleOverrides: {
        input: {
          color: '#FFF', // Set the color to white
          // Add other styles as needed
        },
        underline: {
          '&:before': {
            borderBottomColor: '#2b303b', // Semi-transparent underline
            borderBottomWidth: '1px'
          },
          '&:hover:before': {
            borderBottomColor: 'rgb(0, 208, 189)', // Solid underline on hover
            borderBottomWidth: '1px'
          },
          '&:after': {
            borderBottomColor: 'rgb(0, 208, 189)', // Solid underline on focus
            borderBottomWidth: '1px'
          },
        },
      },
    },
    
  },
});

const AssetDetailsModal = ({ isOpen, onClose, images, name, selectedAsset , setSelectedAsset,  price, status, squareFootage, id, AssetType , noOfRooms, total_floor_count, floor, furniture, asset_condition, heating, amenities, township, address, rent, sale, assetTypes, description, contactsData, userId, notes, setUpdateTrigger, setContactsData, availability_start_date , availability_end_date}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const { t, i18n } = useTranslation();
  const [iconColor, setIconColor] = useState('#FFF');
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name || "");
  const [editedDescription, setEditedDescription] = useState(description || "");
  const [editedNotes, setEditedNotes] = useState(notes || "");
  const [editedAssetStatus, setEditedAssetStatus] = useState(rent ? "FOR RENT" : "FOR SALE");
  const [editedTownship, setEditedTownship] = useState(township || "");
  const [editedAddress, setEditedAddress] = useState(address || "");
  const [editedSquareFootageText, setEditedSquareFootageText] = useState(squareFootage || "");
  const [editedPrice, setEditedPrice] = useState(price || "");
  const[editedStatus, setEditedStatus] = useState(status || "");
  const [editedAssetType, setEditedAssetType] = useState(AssetType || "");
  const [editedTotalFloorCount, setEditedTotalFloorCount] = useState(total_floor_count || "");
  const [editedFloor, setEditedFloor] = useState(floor|| "");
  const [editedNumberOfRooms, setEditedNumberOfRooms] = useState(noOfRooms|| "");
  const [editedFurniture, setEditedFurniture] = useState(furniture);
  const [editedAssetCondition, setEditedAssetCondition] = useState(asset_condition|| "");
  const [editedHeating, setEditedHeating] = useState(heating|| "");
  const [isMobile, setIsMobile] = useState(false);
  const [assetOwner, setAssetOwner] = useState(null);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAmenitiesFetched, setIsAmenitiesFetched] = useState(false);
  const [editedAssetOwnerName, setEditedAssetOwnerName] = useState("");
  const [editedAssetOwnerPhoneNumber, setEditedAssetOwnerPhoneNumber] = useState("");
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const [isClientsTabActive, setIsClientsTabActive] = useState(false); 
  const selectRef = useRef(null);
  const outerTheme = useTheme();
  // Assuming you have a way to fetch these dates from the database
  const [availabilityDates, setAvailabilityDates] = useState({
    // Convert string dates to Date objects
    startDate: new Date(availability_start_date),
    endDate: new Date(availability_end_date)
  });
  
  const [state, setState] = useState([
    {
      // Use the Date objects for startDate and endDate
      startDate: availabilityDates.startDate,
      endDate: availabilityDates.endDate,
      key: 'selection',
    },
  ]);
  
  // Function to handle date change
  const handleDateChange = (startDate, endDate) => {
    // Assuming startDate and endDate are already Date objects
    console.log('New Start Date:', startDate, 'New End Date:', endDate);
  
    // Update state with the new dates
    setState([{ startDate, endDate, key: 'selection' }]);
  };
  // Determine the currency based on the current language
  const [currency, setCurrency] = useState('euro');
  const [exchangeRate, setExchangeRate] = useState(1); // Default to 1 for USD
  async function fetchExchangeRate(fromCurrency, toCurrency) {
    // Fetch the exchange rate from a financial API
    // For simplicity, returning a static value
    return 117.5; 
  }
  
  useEffect(() => {
    // Detect language change and update currency and exchange rate accordingly
    const updateCurrencyAndRate = async () => {
      if (i18n.language === 'sr') {
        const rate = await fetchExchangeRate('euro', 'rsd');
        setExchangeRate(rate);
        setCurrency('rsd');
      } else {
        setExchangeRate(1); // Reset to default for USD
        setCurrency('euro');
      }
    };

    updateCurrencyAndRate();
  }, [i18n.language]);

  const formattedConvertedPrice = (price * exchangeRate).toLocaleString('sr-RS', {
    maximumFractionDigits: 2, // Adjust this as needed for the desired number of decimal places
    minimumFractionDigits: 2 // Ensures consistency in the display of prices
  });
  
  useEffect(() => {
    fetch(`/api/asset_owners/${id}`)
      .then(response => {
        console.log('Response:', response);
        return response.json();
      })
      .then(data => {
        console.log('Data:', data);
        setAssetOwner(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${API_URL}/api/contacts/${userId}`)
    .then(response => response.json())
    .then(data => setContactsData(data))
    .catch(error => console.error('Error:', error));
}, []);

  useEffect(() => {
    // Fetch amenities data from the backend API
    fetch(`${API_URL}/api/filter/amenities`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch amenities data');
        }
        return response.json();
      })
      .then(data => {
        // Set the fetched amenities data in the state
        setAmenitiesList(data.amenities);
  
        // Update checkedItems
        setCheckedItems(data.amenities.reduce((obj, amenity) => {
          obj[amenity] = true;
          return obj;
        }, {}));
  
        // Update isAmenitiesFetched
        setIsAmenitiesFetched(true);
      })
      .catch(error => {
        console.error('Error fetching amenities data:', error);
      });
  }, []);
  
  useEffect(() => {
    if (isEditing && isAmenitiesFetched) {
      setCheckedItems(
        amenitiesList.reduce((obj, amenity) => {
          obj[amenity] = amenities.includes(amenity);
          return obj;
        }, {})
      );
    }
  }, [isEditing, amenitiesList, amenities, isAmenitiesFetched]);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

  const saveButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const buttonsBarRef = useRef(null);


  const [hoveredItem, setHoveredItem] = useState(null);

  const handleEditAsset = () => {
    setIsEditing(true);
    setIconColor('#00D0BD');
  };
  const fetchContactTypes = async () => {
    try {
      const response = await fetch(`${API_URL}/api/contact_types`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const contactTypes = await response.json();
      return contactTypes;
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };



// Updated function to upload a file using a pre-signed URL and return success status
async function uploadFileToS3(file, presignedUrl) {
  try {
    console.log('Sending PUT request to S3');
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (response.ok) {
      console.log('File uploaded successfully');
      return true; // Return true to indicate success
    } else {
      console.error('Failed to upload file');
      return false; // Return false to indicate failure
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    return false; // Return false to indicate failure
  }
}


// Helper function to upload an image
// Adjusted helper function to upload an image to include accessUrl
async function uploadImage(file, filename, id) {
  const payload = {
    fileName: filename,
    contentType: file.type,
    assetId: id // Pass assetId to associate the image with an asset
  };

  try {
    const response = await fetch(`${API_URL}/api/users/${userId}/upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to get pre-signed URL');
    }

    const { presignedUrl, accessUrl } = await response.json(); // Now also retrieving accessUrl
    const uploadSuccess = await uploadFileToS3(file, presignedUrl);

    if (!uploadSuccess) {
      console.error('Failed to upload file to S3');
      return { success: false, message: 'Failed to upload file to S3', accessUrl: null };
    } else {
      console.log('File uploaded to S3 successfully');
      return { success: true, message: 'File uploaded to S3 successfully', accessUrl: accessUrl }; // Return accessUrl on success
    }
  } catch (error) {
    console.error('Error in file upload process:', error);
    return { success: false, message: `Error in file upload process: ${error.message}`, accessUrl: null };
  }
}

// Helper function to check if the date is valid and not the default Unix epoch time
function isValidDate(date) {
  return date && new Date(date).getTime() > 0;
}

const updateAssetImagesInDatabase = async (assetId, imageUrls) => {
  try {
    const response = await fetch(`${API_URL}/api/data/${assetId}/images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ imageUrls })
    });

    if (!response.ok) {
      throw new Error(`Failed to update asset images in database: ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Successfully updated asset images in database:', result);
  } catch (error) {
    console.error('Failed to update asset images in database:', error);
  }
};

const handleDeleteImageMobile = async (index, imageId, event) => {
  event.stopPropagation();

  // Directly remove the image from the local state if it doesn't have an imageId
  if (!imageId) {
    console.warn('Image ID is undefined, removing image from local state only.');
    const updatedSideImages = [...sideImages];
    updatedSideImages.splice(index, 1);
    setSideImages(updatedSideImages);
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/assets/image-url/${imageId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch image URL');
    }

    const { imageUrl } = await response.json();

    if (!imageUrl.startsWith('https://assets-galary.s3.eu-north-1.amazonaws.com/img/')) {
      throw new Error('Invalid image URL');
    }
    // Invalidate cache for the image being deleted
    const cache = await caches.open('asset-images');
    await cache.delete(imageUrl);
    console.log(`Cache delete successful for ${imageUrl}`);

    const deleteResponse = await fetch(`${API_URL}/api/assets/delete-image`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ imageUrl })
    });

    if (!deleteResponse.ok) {
      throw new Error('Failed to delete image from S3');
    }

    const updatedSideImages = [...sideImages];
    updatedSideImages.splice(index, 1);
    setSideImages(updatedSideImages);

    console.log('Successfully deleted image from side images:', imageId);
  } catch (error) {
    console.error('Failed to delete image:', error);
  }
};
const handleDeleteImage = async (index, imageId, event) => {
  event.stopPropagation();

  if (!imageId) {
    console.error('Image ID is undefined');
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/assets/image-url/${imageId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch image URL');
    }

    const { imageUrl } = await response.json();

    if (!imageUrl.startsWith('https://assets-galary.s3.eu-north-1.amazonaws.com/img/')) {
      throw new Error('Invalid image URL');
    }
    // Invalidate cache for the image being deleted
    const cache = await caches.open('asset-images');
    await cache.delete(imageUrl);
    console.log(`Cache delete successful for ${imageUrl}`);

    const deleteResponse = await fetch(`${API_URL}/api/assets/delete-image`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ imageUrl })
    });

    if (!deleteResponse.ok) {
      throw new Error('Failed to delete image from S3');
    }

    const updatedSideImages = [...sideImages];
    updatedSideImages.splice(index, 1);
    setSideImages(updatedSideImages);


    console.log('Successfully deleted image from side images:', imageId);
  } catch (error) {
    console.error('Failed to delete image:', error);
  }
};


const updatedImages = images?.sort((a, b) => a.imageId - b.imageId) || [];


  // Corrected and optimized version of the provided code snippet
  const handleSave = async (e) => {
    const timestamp = new Date().toISOString();
    e.stopPropagation();
    const contactTypes = await fetchContactTypes();
    // Create the amenities array from checked items
    const amenitiesArray = Object.keys(checkedItems).filter(key => checkedItems[key]);
    const amenitiesJSON = JSON.stringify(amenitiesArray);
     // Gather the main image file if it exists

// Initialize variables outside of try block to widen their scope
let newMainImageUrl = '';

try {
  // Process main image
  const mainImageProcess = mainImage.file ? uploadImage(mainImage.file, mainImageFile.filename, id) : Promise.resolve('No main image file to upload');

  const sideImagesProcesses = sideImages.map((sideImage, index) => {
    const uniqueString = Math.random().toString(36).substring(2, 15);
    const filename = sideImageFiles[index] ? sideImageFiles[index].filename : `default_filename_${uniqueString}`;
    return sideImage.file ? uploadImage(sideImage.file, filename, id) : Promise.resolve(`No file for side image at index ${index}`);
  });

  const mainImageResult = await mainImageProcess; // This should now return an object with `accessUrl`
  const sideImagesResults = await Promise.all(sideImagesProcesses); 

  // After successfully processing the images and obtaining their new URLs
  newMainImageUrl = mainImageResult.accessUrl ? `${mainImageResult.accessUrl}?timestamp=${encodeURIComponent(timestamp)}` : '';
  const newSideImagesUrls = sideImagesResults.map(result => result.accessUrl ? `${result.accessUrl}?timestamp=${encodeURIComponent(timestamp)}` : '');

  // Invalidate cache for the main image if it's being replaced
if (mainImage.file && mainImageResult.accessUrl) {
  const cache = await caches.open('asset-images');
  await cache.delete(mainImageResult.accessUrl); // Assuming mainImageResult.accessUrl is the old URL
}

// Invalidate cache for side images being replaced
await Promise.all(sideImagesResults.map(async (result, index) => {
  if (sideImages[index].file && result.accessUrl) {
    const cache = await caches.open('asset-images');
    await cache.delete(result.accessUrl); // Assuming result.accessUrl is the old URL
  }
}));
    // Combine the main image URL with the side image URLs
    const allImageUrls = [newMainImageUrl, ...newSideImagesUrls].filter(url => url.startsWith('https://assets-galary.s3.eu-north-1.amazonaws.com/img/'));



if (isNewMainImage || isNewSideImage) {
  await updateAssetImagesInDatabase(id, allImageUrls);
}

console.log('newMainImageUrl:', newMainImageUrl); // Debugging log
console.log('newSideImagesUrls:', newSideImagesUrls); // Debugging log
console.log('isNewMainImage:', isNewMainImage); // Debugging log
console.log('isNewSideImage:', isNewSideImage); // Debugging log


  // Reset the flags after saving
  setIsNewMainImage(false);
  setIsNewSideImage(false);
  
  // Convert date to YYYY-MM-DD format
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
};

    // Prepare the data to be sent with the PUT request
    const data = {
      name: editedName || null,
      township: editedTownship || null,
      address: editedAddress || null,
      squareFootage: editedSquareFootageText || null,
      total_floor_count: editedTotalFloorCount || null,
      floor: editedFloor || null,
      furniture: editedFurniture || null,
      asset_condition: editedAssetCondition || null,
      heating: editedHeating || null,
      description: editedDescription || null,
      amenities: amenitiesJSON || null,
      AssetType: editedAssetType || null,
      price: editedPrice || null,
      status: editedStatus || null,
      noOfRooms: editedNumberOfRooms || null,
      rent: Number(editedAssetStatus === "FOR RENT"),
      sale: Number(editedAssetStatus === "FOR SALE"),
      notes: editedNotes || null,
      images: images,
      availability_start_date: formatDate(state[0].startDate),
      availability_end_date: formatDate(state[0].endDate),
   // Use the array of uploaded image URLs
    };
  
    try {
      // Make a PUT request to update the asset
      const updateResponse = await fetch(`${API_URL}/api/data/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!updateResponse.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Parse and log the response
      const result = await updateResponse.json();
      console.log("Result: ", result);
  
      // Update the availabilityDates state with the new values
      setAvailabilityDates({
        startDate: new Date(formatDate(state[0].startDate)),
        endDate: new Date(formatDate(state[0].endDate))
      });
      // Update selectedAsset with the edited data
      setSelectedAsset({
        ...selectedAsset,
        ...data,
      });
      console.log("data", data);
  
      // Set editing state and UI elements back to default
      setIsEditing(false);
      setIconColor('#FFF');
      
  
      // Update asset owner if a new one is selected
      const selectedContact = contactsData.find(contact => contact.name === editedAssetOwnerName);
      if (selectedContact) {
        const assetOwnerType = contactTypes.find(type => type.type_name === 'asset_owner');
        let assetOwnerResponse;
  
        // Determine if we need to create a new asset owner or update an existing one
        const currentAssetOwner = assetOwner.find(assetOwner => assetOwner.asset_id === id);
        if (currentAssetOwner) {
          assetOwnerResponse = await fetch(`${API_URL}/api/asset_owners/${id}/${currentAssetOwner.contact_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              new_contact_id: selectedContact.contact_id,
              type_id: assetOwnerType ? assetOwnerType.type_id : null,
            }),
          });
        } else {
          assetOwnerResponse = await fetch(`${API_URL}/api/asset_owners`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              asset_id: id,
              contact_id: selectedContact.contact_id,
              type_id: assetOwnerType ? assetOwnerType.type_id : null,
            }),
          });
        }
  
        // Parse and log the asset owner update response
        const assetOwnerResult = await assetOwnerResponse.json();
        console.log(assetOwnerResult);
  
        // Update the assetOwner state
        setAssetOwner([{
          contact_id: selectedContact.contact_id,
          name: editedAssetOwnerName,
          phone_number: editedAssetOwnerPhoneNumber,
        }]);
      }
      setUpdateTrigger(prev => !prev);
    } catch (error) {
      console.error('Error:', error);
    }
  } catch (error) {
    console.error('Error in image upload process:', error);
    // Handle error
  }
  
  }
  

const handleCancel = (e) => {
  e.stopPropagation(); // This will stop the event from bubbling up
  setEditedName(name);
  setEditedDescription(description); // Use the translation function here
  setEditedAssetStatus(rent ? "FOR RENT" : "FOR SALE");
  setEditedTownship(township);
  setEditedAddress(address);
  setEditedSquareFootageText(squareFootage);
  setEditedPrice(price);
  setEditedAssetType(AssetType);
  setEditedTotalFloorCount(total_floor_count);
  setEditedFloor(floor);
  setEditedNumberOfRooms(noOfRooms);
  setEditedFurniture(furniture);
  setEditedAssetCondition(asset_condition);
  setEditedHeating(heating);
  setIsEditing(false);
  setIconColor('#FFF');
};

const handleCancelMobile = () => {
  setEditedName(name);
  setEditedDescription(description); // Use the translation function here
  setEditedAssetStatus(rent ? "FOR RENT" : "FOR SALE");
  setEditedTownship(township);
  setEditedAddress(address);
  setEditedSquareFootageText(squareFootage);
  setEditedPrice(price);
  setEditedAssetType(AssetType);
  setEditedTotalFloorCount(total_floor_count);
  setEditedFloor(floor);
  setEditedNumberOfRooms(noOfRooms);
  setEditedFurniture(furniture);
  setEditedAssetCondition(asset_condition);
  setEditedHeating(heating);
  setIsEditing(false);
  setIconColor('#FFF');
};


  const modalRef = useRef(null);
  const clientsTabRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [activeElement, setActiveElement] = useState(null);

    async function getImageUrlIfExists(imageId) {
      try {
        const response = await fetch(`${API_URL}/api/images/exist/${imageId}`);
        if (response.ok) {
          const data = await response.json();
          // Extract filename from the existing image URL
          const urlParts = data.image_url.split('/');
          const filename = urlParts[urlParts.length - 1];
          return { imageUrl: data.image_url, filename };
        } else {
          return null; // Image does not exist, or an error occurred
        }
      } catch (error) {
        console.error('Failed to check image URL', error);
        return null;
      }
    }
    


const isReplacingExistingImage = false;
function generateNewFilename(file) {
  // Implementation to generate a new filename based on the file or other logic
  // This is a placeholder. For example, you could use the current timestamp
  return `new_filename_${Date.now()}`;
}
const [mainImage, setMainImage] = useState({ file: null, filename: '', tempUrl: '' });
// Change mainImageFile to use useState for state management
const [mainImageFile, setMainImageFile] = useState({ file: null, filename: '' });
const [sideImageFiles, setSideImageFiles] = useState([]);
const [sideImages, setSideImages] = useState(updatedImages.map(image => ({ file: null, tempUrl: image.imageUrl })));
const [isNewMainImage, setIsNewMainImage] = useState(false);
const [isNewSideImage, setIsNewSideImage] = useState(false);

useEffect(() => {
  if (isEditing) {
    const newMainImage = updatedImages.length > 0 ? updatedImages[0].imageUrl : null;
    // Only update if there's a change
    if (newMainImage && mainImage.tempUrl !== newMainImage) {
      setMainImage(prevMainImage => ({ ...prevMainImage, tempUrl: newMainImage }));
    }

    updatedImages.forEach((image, index) => {
      if (sideImages[index] && sideImages[index].tempUrl !== image.imageUrl) {
        setSideImages(prevSideImages => {
          let newSideImages = [...prevSideImages];
          newSideImages[index] = { ...newSideImages[index], tempUrl: image.imageUrl };
              setSideImages(newSideImages);
        });
      }
    });
  }
}, [isEditing, updatedImages]);
const handleFileMainChange = async (event) => {
  console.log("handleFileMainChange start");
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    const tempUrl = URL.createObjectURL(file);
    console.log("Selected main file:", file);

    setMainImage({ file, filename: file.name, tempUrl });
    console.log("Updated mainImage:", { file, filename: file.name, tempUrl });

    const newSideImages = [...sideImages];
    console.log("Initial sideImages:", newSideImages);

    if (newSideImages.length > 0) {
      newSideImages[0] = { file, tempUrl };
    } else {
      newSideImages.push({ file, tempUrl });
    }
    setSideImages(newSideImages);
    console.log("Updated sideImages:", newSideImages);

    const isReplacingExistingImage = true;
    console.log("isReplacingExistingImage:", isReplacingExistingImage);

    if (isReplacingExistingImage && images && images.length > 0) {
      const existingImageInfo = await getImageUrlIfExists(images[0].imageId);
      console.log("Existing image info:", existingImageInfo);

      if (existingImageInfo) {
        setMainImageFile({ file, filename: existingImageInfo.filename });
      } else {
        setMainImageFile({ file, filename: generateNewFilename(file) });
        setIsNewMainImage(true);
      }
      console.log("Updated mainImageFile:", { file, filename: existingImageInfo ? existingImageInfo.filename : generateNewFilename(file) });
    } else {
    
      setIsNewMainImage(true);
      console.log("New mainImageFile:", mainImageFile);
    }
  }
};
const handleNewImageChange = async (event, startIndex = 0) => {
  const files = Array.from(event.target.files).slice(0, 10 - startIndex); // Adjust the limit based on the startIndex

  let imagesUpdated = false;

  files.forEach((file, fileIndex) => {
    const tempUrl = URL.createObjectURL(file);
    const imageObject = { file, tempUrl };

    // Update side images
    setSideImages(prevSideImages => {
      const updatedSideImages = [...prevSideImages];
      // Ensure not to exceed the array's length and the 10 images limit
      if (startIndex + fileIndex < 10) {
        updatedSideImages[startIndex + fileIndex] = imageObject;
        imagesUpdated = true;
      }
      return updatedSideImages;
    });
  });

  // Flag that new side images have been added
  if (imagesUpdated) {
    setIsNewSideImage(true);
  }
};
const handleFileChangeSide = async (event, index) => {
  console.log(`handleFileChangeSide start for index ${index}`);
  if (index < 0 || index > 9) {
    console.error("Index out of bounds. Only indices 0-9 are allowed.");
    return;
  }

  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    const tempUrl = URL.createObjectURL(file);
    console.log(`Selected side file for index ${index}:`, file);

    let newSideImages = [...sideImages];
    newSideImages[index] = { file, tempUrl };
    setSideImages(newSideImages);
    console.log(`Updated sideImages for index ${index}:`, newSideImages);

    let newSideImageFiles = [...sideImageFiles];
    const existingImage = images[index];
    const isReplacingExistingImage = existingImage && existingImage.imageId;
    console.log(`isReplacingExistingImage for index ${index}:`, isReplacingExistingImage ? `Replacing existing image with ID: ${existingImage.imageId}` : "No. We are adding a new image");

    if (isReplacingExistingImage) {
      const existingFilename = (await getImageUrlIfExists(existingImage.imageId)).filename;
      newSideImageFiles[index] = { file, filename: existingFilename };
    } else {
      newSideImageFiles[index] = { file, filename: generateNewFilename(file) };
      setIsNewSideImage(true);
    }
    setSideImageFiles(newSideImageFiles);
    console.log(`Updated sideImageFiles for index ${index}:`, newSideImageFiles);

    if (index === 0) {
      setMainImage({ file, tempUrl });
      console.log("Updated mainImage due to side image update at index 0:", { file, tempUrl });
    }
  }
};



  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && 
        (!clientsTabRef.current || !clientsTabRef.current.contains(event.target)) &&
        !menuOpen) { // Don't close the modal when the dropdown is open
      if (activeElement === null) { // Check if there's no active element
        onClose();
      }
    }
  }, [onClose, activeElement, menuOpen]);
  
  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
  
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]); // Only re-run this effect if handleClickOutside changes
  const handleImageClick = (index) => {
    setSelectedIndex(index);

  };

  const handleFlipBack = () => {
    setIsFlipped(false);
  };

  const modalClassName = isOpen ? "open" : "modal";

  const handleClose = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleMobileClose = () => {
    // Call the onClose function
    onClose();
  };

  
  if (!isOpen) return null;
// Remove non-numeric characters from price
const numericPrice = (price || '').replace(/[^0-9.]/g, '');

// Convert price to number
const pricePerNight = !isNaN(parseFloat(numericPrice)) ? parseFloat(numericPrice) : 0;

// Check if price is a valid number
const monthlyPrice = pricePerNight * 30;


// Format the monthly price with two decimal places and commas for thousands separation
const formattedMonthlyPrice = !isNaN(monthlyPrice) ? monthlyPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A';


  // Function to convert square footage to square meters if language is Serbian
  const squareFootageText = i18n.language === 'sr' ? `${Math.round(squareFootage * 0.092903)}m²` : `${squareFootage}ft²`;
  const rentBoolean = Boolean(rent);
  const saleBoolean = Boolean(sale);
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handlePrevClick = () => {
    setSelectedIndex((oldIndex) => {
      if (oldIndex === 0) {
        return images.length - 1; // go to the last image
      }
      return oldIndex - 1;
    });
  };

  const handleNextClick = () => {
    setSelectedIndex((oldIndex) => (oldIndex + 1) % images.length); // go to the next image, or to the first image if it's the last one
  };

  // Combine updatedImages and sideImages for the slider on mobile
  const allImages = sideImages.length > 0 && sideImages.some(img => img.tempUrl)
  ? sideImages.filter(img => img.tempUrl).map(img => img.tempUrl)
  : [sampleimage];

const handleChange = (event, newValue) => {
  setValue(newValue);
  if (newValue === 1) {
    setIsClientsTabActive(true);
  }
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

  // Function to format date
  const formatDate = (dateString) => {
    if (dateString && !(dateString instanceof Date)) {
      const date = new Date(dateString);
      if (!isNaN(date)) {
        return date.toLocaleDateString();
      }
      return '';
    } else if (dateString instanceof Date) {
      return dateString.toLocaleDateString();
    }
    return '';
  };
  

  const renderContent = () => {
    if (isMobile) {
        // Placeholder for mobile content
        return (
          <Root>
          (<ThemeProvider theme={theme}>
            <>
       <div className={`modal-overlay ${modalClassName}`}>
  <div className={`modal-content ${isEditing ? 'editing' : ''}`} ref={modalRef} onClick={(e) => e.stopPropagation()}>
  <IconButton
      style={{ position: 'absolute', top: 22, left: 5, color: '#FFF', padding: '0px' }}
      onClick={handleMobileClose}
    >
      <CloseIcon />
    </IconButton>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {isEditing ? (
             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '30px'}}>
            <CustomTextField
              inputProps={{ 
                style: { color: '#FFF', fontWeight: 300, fontSize: '22px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '10px', width: '700px' }
               }}
              InputProps={{
                 classes: {
                   underline: classes.underline,
                 },
               }}
              variant="standard"
              value={editedName}
              // Add other TextField properties as needed
              onChange={(e) => setEditedName(e.target.value)}
              classes={{
                root: classes.root
              }} />
            </div>
          ) : (
        <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '24px', fontStyle: 'normal', marginLeft: '30px', paddingTop: '15px', fontFamily: 'Montserrat' }}>
          {name}
        </Typography>
          )}
        <IconButton
          style={{ cursor: 'pointer' }}
          onClick={handleEditAsset}
        >
                 <EditIcon color={iconColor} />
        </IconButton>
      </div>
      

  {isEditing ? (
          assetOwner && assetOwner.length > 0 ? (
            assetOwner.map((owner, index) => (
              <div key={index} style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '10px'}}>
  <Select
    variant="standard"
    className={classes.select}
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    style={{ marginRight: '20px', width: '200px' }}
    displayEmpty
    value={editedAssetOwnerName || ''}
    onChange={(e) => {
      setEditedAssetOwnerName(e.target.value);
      const selectedContact = contactsData.find(contact => contact.name === e.target.value);
      if (selectedContact) {
        setEditedAssetOwnerPhoneNumber(selectedContact.phone_number);
      }
    }}
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '15px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '10px' }
    }}
    renderValue={(selected) => {
      if (selected === '') {
        return <em style={{ color: 'rgb(255, 255, 255)', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '0px' }}> {t("Asset Owner's Name")}</em>;
      }
      return selected;
    }}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        style: menuItemStyle
      },
      getcontentanchorel: null,
      classes: { 
        paper: classes.paperTwo,
        list: classes.itemTwo,
      },
    }}
  >
    {contactsData.map((contact, index) => (
      <MenuItem key={`contact-${contact.name}-${index}`} value={contact.name} >{contact.name}</MenuItem>
    ))}
  </Select>
                <TextField
                  variant="standard"
                  value={editedAssetOwnerPhoneNumber || owner.phone_number}
                  onChange={(e) => setEditedAssetOwnerPhoneNumber(e.target.value)}
                  placeholder={t("Asset Owner's Phone Number")}
                  style={{ marginRight: '10px' }}
                  inputProps={{ 
                    style: { 
                      color: editedAssetOwnerPhoneNumber ? '#FFF' : '#FFF', // Apply red color if there's an edited phone number
                      fontWeight: 300, 
                      fontSize: '16px', 
                      fontStyle: 'normal',  
                      fontFamily: 'Montserrat',
                      pointerEvents: 'none', // Prevents cursor from changing to text cursor on hover
                      userSelect: 'none', // Prevents text selection
                    }
                  }}
                  InputProps={{
                    readOnly: true, // Makes the field read-only to prevent user input
                    classes: {
                      underline: classes.underline,
                    },
                  }}
                />
              </div>
            ))
          ) : (
      <div style={{ position: 'relative',  display: 'flex', flexDirection: 'row',  marginLeft: '0px', marginBottom: '10px'}}>
  <Select
    variant="standard"
    className={classes.select}
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    style={{ marginRight: '20px', width: '200px' }}
    displayEmpty
    value={editedAssetOwnerName || ''}
    onChange={(e) => {
      setEditedAssetOwnerName(e.target.value);
      const selectedContact = contactsData.find(contact => contact.name === e.target.value);
      if (selectedContact) {
        setEditedAssetOwnerPhoneNumber(selectedContact.phone_number);
      }
    }}
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '15px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '10px' }
    }}
    renderValue={(selected) => {
      if (selected === '') {
        return <em style={{ color: 'rgb(255, 255, 255)', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '0px' }}>{t("Asset Owner's Name")}</em>;
      }
      return selected;
    }}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        style: menuItemStyle
      },
      getcontentanchorel: null,
      classes: { 
        paper: classes.paperTwo,
        list: classes.itemTwo,
      },
    }}
  >
    {contactsData.map((contact, index) => (
      <MenuItem key={`contact-${contact.name}-${index}`} value={contact.name}>{contact.name}</MenuItem>
    ))}
  </Select>
        <TextField
         style={{ marginRight: '20px' }}
         variant="standard"
         inputProps={{ 
          style: { 
            color: editedAssetOwnerPhoneNumber ? '#FFF' : '#FFF', // Apply red color if there's an edited phone number
            fontWeight: 300, 
            fontSize: '16px', 
            fontStyle: 'normal',  
            fontFamily: 'Montserrat',
            pointerEvents: 'none', // Prevents cursor from changing to text cursor on hover
            userSelect: 'none', // Prevents text selection
          }
        }}
        InputProps={{
          readOnly: true, // Makes the field read-only to prevent user input
          classes: {
            underline: classes.underline,
          },
        }}
          value={editedAssetOwnerPhoneNumber || ''}
          onChange={(e) => setEditedAssetOwnerPhoneNumber(e.target.value)}
          placeholder="Phone No"
        />
      </div>
    )
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '30px', paddingTop: '15px' }}>
      {assetOwner && assetOwner.length > 0 && assetOwner.map((owner, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', paddingRight: '15px' }}>
          <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
            {owner.name}
          </Typography>
          <Typography variant="body1" style={{ color: '#83E28D', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat', marginLeft: '10px' }}>
            {owner.phone_number}
          </Typography>
        </div>
      ))}
    </div>
  )}

      {isEditing ? (
    <div style={{width: '545px', height: '88px'}}>
      <div style={{height: '44px', marginLeft: '10px' }}>
      <Select
      className={classes.select}
      variant="standard"
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    value={editedAssetStatus}
    onChange={(e) => setEditedAssetStatus(e.target.value)}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        style: menuItemStyle
      },
      getcontentanchorel: null,
      classes: { 
        paper: classes.paperTwo,
        list: classes.itemTwo,
      },
    }}
  >
  <MenuItem
    key="FOR RENT"
    value="FOR RENT"
    onMouseEnter={() => setHoveredItem("FOR RENT")}
    onMouseLeave={() => setHoveredItem(null)}
    className={hoveredItem === "FOR RENT" ? classes.hoveredItem : null}
  >
  {t('FOR RENT')}
  </MenuItem>
  <MenuItem
    key="FOR SALE"
    value="FOR SALE"
    onMouseEnter={() => setHoveredItem("FOR SALE")}
    onMouseLeave={() => setHoveredItem(null)}
    className={hoveredItem === "FOR SALE" ? classes.hoveredItem : null}
  >
  {t('FOR SALE')}
  </MenuItem>
  </Select>
      </div>
      <div style={{height: '100px', marginLeft: '30px', display: 'flex', flexDirection: 'column'}}>
      <CustomTextField
        variant="standard"
        value={editedTownship}
        onChange={(e) => setEditedTownship(e.target.value)}
        inputProps={{ 
          style: { color: '#FFF', fontWeight: 300, fontSize: '316x', fontStyle: 'normal',  fontFamily: 'Montserrat'}
         }}
        InputProps={{
           classes: {
             underline: classes.underline,
           },
         }}
        // Add other TextField properties as needed
        style={{ marginRight: '30px', width: '135px', marginBottom: '20px' }}
        classes={{
          root: classes.root
        }} />
      <CustomTextField
        value={editedAddress}
        variant="standard"
        onChange={(e) => setEditedAddress(e.target.value)}
        inputProps={{ 
          style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal',fontFamily: 'Montserrat'}
         }}
        InputProps={{
           classes: {
             underline: classes.underline,
           },
         }}
        // Add other TextField properties as needed
        style={{ marginRight: '30px',  marginBottom: '20px', width: '300px' }}
        classes={{
          root: classes.root
        }} />
      
      <CustomTextField
        type="number"
        variant="standard"
        value={editedSquareFootageText}
        onChange={(e) => setEditedSquareFootageText(e.target.value)}
        inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat',  }
         }}
        InputProps={{
           classes: {
             underline: classes.underline,
           },
         }}
        style={{ marginRight: '30px',  marginBottom: '20px',  width: '300px' }}
        classes={{
          root: classes.root
        }} />
      </div>
    </div>
  ) : (
    <div>
      {rentBoolean && <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '17px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('FOR RENT')}</Typography>}
      {saleBoolean && <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '17px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('FOR SALE')}</Typography>}
      {isValidDate(availabilityDates.startDate) && isValidDate(availabilityDates.endDate) && (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', paddingTop: '10px', marginRight: '35px', paddingBottom: '10px ' }}>
      <CalendarTodayIcon style={{ color: 'white', marginRight: '10px' }} />
      <Typography variant="body2" style={{ color: '#00D0BD', fontWeight: 400, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
        {`${formatDate(availabilityDates.startDate)} - ${formatDate(availabilityDates.endDate)}`}
      </Typography>
    </div>
  )}

      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{township}</Typography>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{address} </Typography>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{squareFootageText}</Typography>
    </div>
  )}
  {isEditing ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '90px'}}>
      <CustomTextField
        variant="standard"
        type="number"
        value={editedPrice}
        onChange={(e) => setEditedPrice(e.target.value)}
        inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', paddingTop: '15px', fontFamily: 'Montserrat'}
        }}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
        style={{ marginLeft: '30px', marginTop: '13px', marginBottom: '10px', width: '185px' }}
        classes={{
          root: classes.root
        }} />
  <Select
    value={editedStatus}
    displayEmpty
    variant="standard"
    renderValue={(selected) => {
      if (selected.length === 0) {
        return 'Status';
      }
      return selected;
    }}
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    onChange={(e) => setEditedStatus(e.target.value)}
    className={classes.select}
    classes={{ icon: classes.icon }}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        style: menuItemStyle
      },
      getcontentanchorel: null, // This prevents the menu from being positioned based on the label
      classes: {
        paper: classes.paper,
        list: classes.item,
      },
    }}
    style={{ marginLeft: '50px', marginTop: '13px', width: '185px', marginRight: '10px'}}
  >
    {["New", "Published", "Pre-contract", "Sold", "Leased"].map((item) => (
      <MenuItem
        key={item} // Added key for list stability
        value={item}
        onMouseEnter={() => setHoveredItem(item)}
        onMouseLeave={() => setHoveredItem(null)}
        className={hoveredItem === item ? classes.hoveredItem : null}
      >
        {t(item)}
      </MenuItem>
    ))}
  </Select>
    </div>
  ) : (
    <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'normal' }}>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', marginLeft: '30px', paddingTop: '15px', fontFamily: 'Montserrat', width: '380px'}}> {currency === 'euro' ? `€${price}` : `${formattedConvertedPrice} rsd`}</Typography>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px', paddingTop: '15px' }}>
      <Typography variant="body1" style={{ color: 'rgb(0, 208, 189)', fontWeight: 500, fontSize: '18px', fontStyle: 'normal', fontFamily: 'Montserrat'}}>Status:</Typography>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '18px', fontStyle: 'normal', marginLeft: '10px', paddingRight: '20px', fontFamily: 'Montserrat'}}>{t(status)}</Typography>
    </div>
  </>
  )}   
  {isEditing ? (
    <>
      {sideImages.map((image, index) => (
        <div key={index} style={{ position: 'relative', marginBottom: '10px' }}>
          <img src={image.tempUrl} alt={`Side ${index + 1}`} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          <RemoveCircleOutlineIcon
            style={{
              position: 'absolute',
              top: '14px',
              right: '0px',
              color: 'rgb(255, 0, 0)',
              fontSize: '30px',
              cursor: 'pointer',
            }}
            onClick={(event) => {
              const image = updatedImages[index];
              if (image && image.imageId) {
                handleDeleteImageMobile(index, image.imageId, event);
              }
            }}
          />
          <label htmlFor={`sideImageUploadInput${index}`} style={{ 
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }}>
            <AddCircleOutlineIcon style={{ color: 'rgb(0, 208, 189)', fontSize: '34px'  }} />
          </label>
          <input
            type="file"
            id={`sideImageUploadInput${index}`}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChangeSide(e, index)}
          />
        </div>
      ))}
      {sideImages.length < 10 && (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <label htmlFor="newImageUploadInput" style={{
            position: 'relative',
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#FFF',
            textAlign: 'center',
            padding: '20px',
          }}>
            <AddCircleOutlineIcon style={{ color: 'rgb(0, 208, 189)', fontSize: '48px' }}/>
            <span style={{ color: '#FFF', fontFamily: 'Montserrat' }}>{t('Upload photo')}</span>
          </label>
          <input
            type="file"
            id="newImageUploadInput"
            style={{ display: 'none' }}
            onChange={(e) => handleNewImageChange(e, sideImages.length)} // Ensure this function is defined to handle the new image addition
            multiple
          />
        </div>
      )}
    </>
  ) : (
  <div className="slider" style={{marginTop: '10px'}}>
  <div className="slider-content" style={{ position: 'relative' }}>
    {allImages.length > 1 && (
      <KeyboardArrowLeftIcon
        style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'rgb(0, 208, 189)', fontSize: '2em' }}
        onClick={handlePrevClick}
      />
    )}
    <img src={allImages[selectedIndex]} alt={`Slide ${selectedIndex + 1}`} style={{ width: '100%' }} />
    {allImages.length > 1 && (
      <KeyboardArrowRightIcon
        style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'rgb(0, 208, 189)', fontSize: '2em' }}
        onClick={handleNextClick}
      />
    )}
  </div>
      </div>
  )}
      <div style={{marginTop: '20px', width: '100%', }}>
              <div className='FirstColumn'>
    <div className='assetTypeContainer'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Type')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '10px'}}>
    <Select
      value={editedAssetType}
      onChange={(e) => setEditedAssetType(e.target.value)}
      variant="standard"
      onOpen={() => {
        setMenuOpen(true);
        setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
      }}
      onClose={() => {
        setMenuOpen(false);
        setActiveElement(null); // Reset activeElement to null when the dropdown is closed
      }}
      className={classes.select}
      classes={{ icon: classes.icon }} 
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          style: menuItemStyle
        },
        getcontentanchorel: null, // This prevents the menu from being positioned based on the label
        classes: {
          paper: classes.paper,
          list: classes.item,
        },
      }}
    >
      {assetTypes.map((type) => (
        <MenuItem  key={type} value={type}
        onMouseEnter={() => setHoveredItem(type)}
        onMouseLeave={() => setHoveredItem(null)}
        className={hoveredItem === type ? classes.hoveredItem : null}
        >
          {type}
          </MenuItem>
      ))}
    </Select>
    </div>
  )
  : (
    <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedAssetType)}</Typography>
  )}
    </div>
    <div className='totalFloorCount'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Total Floor Count')}</Typography>
      {isEditing ? (
     <div style={{marginLeft: '30px'}}>
        <CustomTextField
          type="number"
          variant="standard"
          inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '10px', paddingTop: '15px', fontFamily: 'Montserrat'}
        }}
          InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          value={editedTotalFloorCount}
          onChange={(e) => setEditedTotalFloorCount(e.target.value)}
          classes={{
            root: classes.root
          }} />
    </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedTotalFloorCount)}</Typography>
      )}
    </div>
    <div className='FloorCount'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Floor')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '20px'}}>
        <CustomTextField
          type="number"
          variant="standard"
          inputProps={{ 
            min: 0,
            step: 1,
            style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '20px', paddingTop: '15px', fontFamily: 'Montserrat'}
          }}
          InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          value={editedFloor}
          onChange={(e) => setEditedFloor(e.target.value)}
          classes={{
            root: classes.root
          }} />
         </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedFloor)}</Typography>
      )}
    </div>
    <div className='numberOfRoomsContainer'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Number of rooms')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '20px'}}>
       <CustomTextField
         type="text"
         variant="standard"
         value={editedNumberOfRooms}
         onChange={(e) => {
           const value = e.target.value;
           if (/^\d*\.?\d*$/.test(value)) { // Check if the input is a valid float number
             setEditedNumberOfRooms(value);
           }
         }}
         inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '20px', paddingTop: '15px', fontFamily: 'Montserrat'}
        }}
         InputProps={{
           classes: {
             underline: classes.underline,
           },
         }}
         classes={{
           root: classes.root
         }} />
     </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedNumberOfRooms)}</Typography>
      )}
    </div>
  </div>
              <div className='detailsContainer' style={{marginRight: '50px'}}>
              <div className='Viewing'>
              <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Viewing')}</Typography>
              <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '17px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', textTransform: 'capitalize', }}>Mondays & Thursdays</Typography>
              <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>Call for appointment</Typography>
                </div>
                <div className='Amenities' style={{marginBottom: '15px'}}>
    <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Amenities')}</Typography>
    <div className={classes.scrollableDivMobile}>
    {(Array.isArray(isEditing ? amenitiesList : amenities) ? (isEditing ? amenitiesList : amenities) : (typeof amenities === 'string' ? (isJsonString(amenities) ? JSON.parse(amenities) : amenities.split(',')) : [])).map((amenity, index) => (
    <div key={typeof amenity === 'string' ? index : amenity.id} style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginRight: '10px', fontFamily: 'Montserrat', width: '149px', marginLeft: '30px'}}>
        {t(amenity)}
      </Typography>
        {isEditing ? (
          <Checkbox
            style={{ padding: '6px' }}
            icon={<CircleIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checkedIcon={<TripOriginIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checked={checkedItems[amenity] || false} 
            onClick={() => {
              setCheckedItems(prevState => {
                return { ...prevState, [amenity]: !prevState[amenity] };
              });
            }}
          />
        ) : (
          <AmenitiesCheck />
        )}
      </div>
    ))}
  </div>
  </div>
                </div>
          
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}} >
    <div className='Furniture' style={{marginBottom: '15px'}}>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Furniture')}</Typography>
      {isEditing ? (
        <Select 
        value={editedFurniture}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedFurniture(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        style={{marginLeft: '30px'}}
        >
   {["Furnished", "semi furnished", "empty"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            className={hoveredItem === item ? classes.hoveredItem : null}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', textTransform: 'capitalize'}}>{t(furniture)}</Typography>
      )}
    </div>
    <div className='AssetCondition' style={{marginBottom: '15px'}}>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Asset Condition')}</Typography>
      {isEditing ? (
        <Select 
        value={editedAssetCondition}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedAssetCondition(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        style={{marginLeft: '30px'}}
        >
        {["Lux", "Original state", "Renovated"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            className={hoveredItem === item ? classes.hoveredItem : null}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t(asset_condition)}</Typography>
      )}
    </div>
    <div className='Heating'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Heating')}</Typography>
      {isEditing ? (
        <Select 
        value={editedHeating}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedHeating(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        style={{marginLeft: '30px'}}
        >
  {["Central heating", "Gas", "Heat pumps", "Norwegian radiators", "EG", "TA"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            className={hoveredItem === item ? classes.hoveredItem : null}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t(heating)}</Typography>
      )}
    </div>
  </div>
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{display: 'flex', flexDirection: 'column', width: '70%', marginTop: '15px', alignItems: 'center'}}>
      {isEditing && editedAssetStatus === "FOR RENT" && (
        <>
          <Typography style={{color: 'rgb(0, 208, 189)', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat', marginBottom: '10px'}}>
            {t('Availability')}
          </Typography>
          <CalendarComponent onDateChange={handleDateChange} state={state} setState={setState} />
        </>
      )}
    </div>
  </div>
  <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '10px', marginTop: '20px'}}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor: 'rgb(0, 208, 189)', width: value === 0 ? '170px' : '100px'}}}>
      <Tab label={t('Description/Notes')} {...a11yProps(0)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '170px'}} />
      <Tab label={t('Clients')} {...a11yProps(1)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '100px'}} />
    </Tabs>
  </Box>
  <CustomTabPanel value={value} index={0}  style={{marginLeft: '0px'}}>
    <div className="descriptionContainer">
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '20px', fontStyle: 'normal',  fontFamily: 'Montserrat'}}>
        {t('Description')}
      </Typography>
      {isEditing ? (
        <TextField
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.target.value)}
          className={classes.textField}
          multiline
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color,
                borderRadius: '20px'
              },
              '&:hover fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color on hover
                 borderRadius: '20px'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color when focused
                 borderRadius: '20px'
              },
            },
          }}
        />
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '16px', fontStyle: 'normal',  fontFamily: 'Montserrat'}}>
          {description}
        </Typography>
      )}
    </div>
    <div className="notesContainer">
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', paddingTop: '15px', fontFamily: 'Montserrat'}}>
        {t('Notes')}
      </Typography>
      {isEditing ? (
        <TextField
          value={editedNotes}
          onChange={(e) => setEditedNotes(e.target.value)}
          className={classes.textField}
          multiline
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color,
                borderRadius: '20px'
              },
              '&:hover fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color on hover
                 borderRadius: '20px'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(0, 208, 189)', // Change border color when focused
                 borderRadius: '20px'
              },
            },
          }}
        />
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', paddingTop: '15px', paddingRight: '15px', fontFamily: 'Montserrat'}}>
          {notes}
        </Typography>
      )}
    </div>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={1}  style={{justifyContent: 'center', display: 'flex'}}>
    <ClientsTab assetId={id} clientsTabRef={clientsTabRef} setMenuOpen={setMenuOpen} setActiveElement={setActiveElement} userId={userId} isEditing={isEditing}  isClientsTabActive={isClientsTabActive}/>
  </CustomTabPanel>
  {isEditing && (
        <div

        ref={buttonsBarRef} 
        style={{
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '10px',
          paddingTop: '10px',
          backgroundColor: '#1A2033' // Change this to match your modal's background color
        }}>
      <CustomAddCancelButton ref={saveButtonRef} onClick={handleSave}>Save</CustomAddCancelButton>
      <CustomAddCancelButton onClick={handleCancelMobile}>Cancel</CustomAddCancelButton>
        </div>
      )}
        </div>
      </div>

      </>
          </ThemeProvider>
          )
          </Root>
        );
    } else {
  return (
    (
      <Root>
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div className={`modal-overlay ${modalClassName}`} onClick={handleClose}>
  <div className={`modal-content ${isEditing ? 'editing' : ''}`} ref={modalRef} onClick={(e) => e.stopPropagation()}>
  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
    <IconButton
      style={{ cursor: 'pointer' }}
      onClick={handleEditAsset}
    >
      <EditIcon color={iconColor} />
    </IconButton>
    </div>
   <div style={{ width: '100%',flexDirection: 'row', justifyContent: 'space-between' }}>
    {isEditing ? (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '50px'}}>
            <TextField
              variant="standard"
              value={editedName}
              inputProps={{ 
                style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal',  fontFamily: 'Montserrat'}
               }}
               InputProps={{
                  classes: {
                    underline: classes.underline,
                  },
                }}
              onChange={(e) => setEditedName(e.target.value)}
              // Add other TextField properties as needed
            />
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '50px', paddingTop: '15px' }}>
        <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '30px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
          {name}
        </Typography>
        {isValidDate(availabilityDates.startDate) && isValidDate(availabilityDates.endDate) && (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', paddingTop: '20px', marginRight: '35px' }}>
      <CalendarTodayIcon style={{ color: 'white', marginRight: '10px' }} />
      <Typography variant="body2" style={{ color: '#00D0BD', fontWeight: 400, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
        {`${formatDate(availabilityDates.startDate)} - ${formatDate(availabilityDates.endDate)}`}
      </Typography>
    </div>
  )}
      </div>
    )}
  </div>

  {isEditing ? (
    assetOwner && assetOwner.length > 0 ? (
      assetOwner.map((owner, index) => (
        <div key={index} style={{ position: 'absolute', right: 0, top: '13%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '50px'}}>
  <Select
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
    variant="standard"
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    style={{ marginRight: '20px', width: '200px' }}
    displayEmpty
    value={editedAssetOwnerName || (owner ? owner.name : '')}
    onChange={(e) => {
      setEditedAssetOwnerName(e.target.value);
      const selectedContact = contactsData.find(contact => contact.name === e.target.value);
      if (selectedContact) {
        setEditedAssetOwnerPhoneNumber(selectedContact.phone_number);
      }
    }}
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '15px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '10px' }
    }}

    renderValue={(selected) => {
      if (selected === '') {
        return <em style={{ color: 'rgb(255, 255, 255)', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '0px' }}>{t("Asset Owner's Name")}</em>;
      }
      return selected;
    }}
  >
  {contactsData.map((contact, index) => (
    <MenuItem key={`contact-${contact.name}-${index}`} value={contact.name} onMouseEnter={() => setHoveredItem(contact.name)}
    onMouseLeave={() => setHoveredItem(null)}
    style={hoveredItem === contact.name ? hoveredMenuItemStyle : menuItemStyle}>{contact.name}</MenuItem>
  ))}
  </Select>
          <TextField
          variant="standard"
            value={editedAssetOwnerPhoneNumber || owner.phone_number}
            onChange={(e) => setEditedAssetOwnerPhoneNumber(e.target.value)}
            placeholder={t("Asset Owner's Phone Number")}
            inputProps={{ 
              style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal',  fontFamily: 'Montserrat'}
             }}
             InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
          />
        </div>
      ))
    ) : (
      <div style={{ position: 'absolute', right: 0, top: '13%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '50px'}}>
  <Select
  className={classes.select}
  classes={{ icon: classes.icon }} 
  MenuProps={{
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    MenuListProps: {
      style: menuItemStyle
    },
    getcontentanchorel: null, // This prevents the menu from being positioned based on the label
    classes: {
      paper: classes.paper,
      list: classes.item,
    },
  }}
  variant="standard"
    onOpen={() => {
      setMenuOpen(true);
      setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
    }}
    onClose={() => {
      setMenuOpen(false);
      setActiveElement(null); // Reset activeElement to null when the dropdown is closed
    }}
    style={{ marginRight: '20px', width: '200px' }}
    displayEmpty
    value={editedAssetOwnerName || ''}
    onChange={(e) => {
      setEditedAssetOwnerName(e.target.value);
      const selectedContact = contactsData.find(contact => contact.name === e.target.value);
      if (selectedContact) {
        setEditedAssetOwnerPhoneNumber(selectedContact.phone_number);
      }
    }}
    inputProps={{ 
      style: { color: '#FFF', fontWeight: 300, fontSize: '15px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '10px' }
    }}

    renderValue={(selected) => {
      if (selected === '') {
        return <em style={{ color: 'rgb(255, 255, 255)', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '0px' }}>{t("Asset Owner's Name")}</em>;
      }
      return selected;
    }}
  >
  {contactsData.map((contact, index) => (
        <MenuItem
        key={`contact-${contact.name}-${index}`}
        value={contact.name}
        onMouseEnter={() => setHoveredItem(contact.name)}
        onMouseLeave={() => setHoveredItem(null)}
        style={hoveredItem === contact.name ? hoveredMenuItemStyle : menuItemStyle}
      >{contact.name}</MenuItem>
  ))}
  </Select>
        <TextField
    variant="standard"
    value={editedAssetOwnerPhoneNumber || ''}
    onChange={(e) => setEditedAssetOwnerPhoneNumber(e.target.value)}
    placeholder="Phone Number"
    inputProps={{ 
      style: { 
        color: editedAssetOwnerPhoneNumber ? '#FFF' : '#FFF', // Apply red color if there's an edited phone number
        fontWeight: 300, 
        fontSize: '16px', 
        fontStyle: 'normal',  
        fontFamily: 'Montserrat',
        pointerEvents: 'none', // Prevents cursor from changing to text cursor on hover
        userSelect: 'none', // Prevents text selection
      }
    }}
    InputProps={{
      readOnly: true, // Makes the field read-only to prevent user input
      classes: {
        underline: classes.underline,
      },
    }}
        />
      </div>
    )
  ) : (
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '50px', paddingTop: '15px' }}>
  {assetOwner && assetOwner.length > 0 && assetOwner.map((owner, index) => (
      <div key={index} style={{ position: 'absolute', right: 0, top: '13%', display: 'flex', justifyContent: 'space-between', width: 'fit-content', paddingRight: '15px' }}>
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
          {owner.name}
        </Typography>
        <Typography variant="body1" style={{ color: '#83E28D', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', fontFamily: 'Montserrat', marginLeft: '10px' }}>
          {owner.phone_number}
        </Typography>
      </div>
    ))}
  </div>
  )}

      {isEditing ? (
    <div style={{width: '545px', height: '88px'}}>
      <div style={{height: '44px', marginLeft: '30px' }}>
      <Select
  variant="standard"
  onOpen={() => {
    setMenuOpen(true);
    setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
  }}
  onClose={() => {
    setMenuOpen(false);
    setActiveElement(null); // Reset activeElement to null when the dropdown is closed
  }}
    value={editedAssetStatus}
    onChange={(e) => setEditedAssetStatus(e.target.value)}
    className={classes.select}
    inputProps={{
      classes: {
        icon: classes.icon,
      },
    }}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        style: menuItemStyle
      },
      getcontentanchorel: null,
      classes: {
        paper: classes.paperTwo,
        list: classes.itemTwo,
      },
    }}
  >
  <MenuItem
    key="FOR RENT"
    value="FOR RENT"
    onMouseEnter={() => setHoveredItem("FOR RENT")}
    onMouseLeave={() => setHoveredItem(null)}
    style={hoveredItem === "FOR RENT" ?  hoveredMenuItemStyle : menuItemStyle}
  >
  {t('FOR RENT')}
  </MenuItem>
  <MenuItem
    key="FOR SALE"
    value="FOR SALE"
    onMouseEnter={() => setHoveredItem("FOR SALE")}
    onMouseLeave={() => setHoveredItem(null)}
    style={hoveredItem === "FOR SALE" ?  hoveredMenuItemStyle : menuItemStyle}
  >
  {t('FOR SALE')}
  </MenuItem>
  </Select>
      </div>
      <div style={{height: '44px', marginLeft: '50px'}}>
      <TextField
      variant="standard"
        value={editedTownship}
        onChange={(e) => setEditedTownship(e.target.value)}
        inputProps={{ 
          style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal',  fontFamily: 'Montserrat'}
         }}
         InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          style={{ marginRight: '50px', width: '135px' }}
        // Add other TextField properties as needed
      />
      <TextField
        value={editedAddress}
        variant="standard"
        onChange={(e) => setEditedAddress(e.target.value)}
        inputProps={{ 
          style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal',fontFamily: 'Montserrat' , width: '250px'}
         }}
         InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
        // Add other TextField properties as needed
      />
      </div>
      <TextField
      variant="standard"
          type="number"
        value={editedSquareFootageText}
        onChange={(e) => setEditedSquareFootageText(e.target.value)}
        inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', marginTop: '-10px' }
         }}
         InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          style={{marginLeft: '50px'}}
      />
    </div>
  ) : (
    <div style={{width: '545px', height: '88px'}}>
      {rentBoolean && <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{t('FOR RENT')}</Typography>}
      {saleBoolean && <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{t('FOR SALE')}</Typography>}
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{township}, {address}</Typography>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '50px', fontFamily: 'Montserrat'}}>{squareFootageText}</Typography>
    </div>
  )}
  {isEditing ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <TextField
      variant="standard"
        type="number"
        value={editedPrice}
        onChange={(e) => setEditedPrice(e.target.value)}
        inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', paddingTop: '15px', fontFamily: 'Montserrat'}
        }}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
        style={{ marginLeft: '50px', marginTop: '13px', width: '185px' }}
      />
      <Select 
        value={editedStatus}
        variant="standard"
        ref={selectRef}
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedStatus(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        style={{ marginLeft: '50px', marginTop: '13px', width: '185px', marginRight: '10px'}}
      >
        {["New", "Published", "Pre-contract", "Sold", "Leased"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            style={hoveredItem === item ? hoveredMenuItemStyle : menuItemStyle}
          >
            {t(item)}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'normal' }}>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '22px', fontStyle: 'normal', marginLeft: '50px', paddingTop: '15px', fontFamily: 'Montserrat', width: '380px'}}> {currency === 'euro' ? `€${price}` : `${formattedConvertedPrice} rsd`}</Typography>
      <Typography variant="body1" style={{ color: 'rgb(0, 208, 189)', fontWeight: 500, fontSize: '18px', fontStyle: 'normal', marginLeft: '300px', paddingTop: '15px', fontFamily: 'Montserrat'}}>Status:</Typography>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '18px', fontStyle: 'normal', marginLeft: '10px', paddingTop: '15px', fontFamily: 'Montserrat'}}>{t(status)}</Typography>
  </div>
  )}    
  <div className="carousel-wrapper">
            <div className={`carousel ${isFlipped ? 'flipped' : ''}`}>
            {isEditing ? (
    <>
      <div className="imageContainer">
        <div style={{ position: 'relative', width: '345px', height: 'auto' }}>
        <img src={mainImage && mainImage.tempUrl ? mainImage.tempUrl : sampleimage} alt="Main" style={{ width: '100%', height: '340px' }} />
          <label htmlFor="mainImageUploadInput" style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '340px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: add a semi-transparent overlay
            color: '#FFF',
            textAlign: 'center',
          }}>
            <AddCircleOutlineIcon style={{ color: 'rgb(0, 208, 189)', fontSize: '48px' }}/>
            <span style={{ color: '#FFF', fontFamily: 'Montserrat' }}>Change photo</span>
          </label>
        </div>
        <input type="file" id="mainImageUploadInput" style={{ display: 'none' }} onChange={handleFileMainChange} />

        <div className="side-images scrollbar4">
    {Array.from({ length: 10 }, (_, index) => (
      <Root key={index}>
        <div style={{ position: 'relative', width: '85px', height: '67px', border: '2px dashed rgb(0, 208, 189)' }}>
          {sideImages[index] && sideImages[index].tempUrl ? (
            <>
              <img
                src={sideImages[index].tempUrl}
                alt={`Side ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
  <RemoveCircleOutlineIcon
    style={{
      position: 'absolute',
      top: '-4px',
      left: '0px',
      color: 'rgb(255, 0, 0)', // Color for the delete icon
      fontSize: '20px',
      cursor: 'pointer',
    }}
    onClick={(event) => {
      const image = updatedImages[index];
      if (image && image.imageId) {
        handleDeleteImage(index, image.imageId, event);
      } else {
        // Handle deletion of an image that hasn't been uploaded yet
        const updatedSideImages = [...sideImages];
        updatedSideImages.splice(index, 1);
        setSideImages(updatedSideImages);
        console.log('Removed image from side images:', index);
      }
    }}
  />
            </>
          ) : (
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              width: '100%', 
              height: '100%' 
            }}>
              {/* Placeholder or additional content here */}
            </div>
          )}
          {/* Adjust the label size or position here */}
          <label htmlFor={`sideImageUploadInput${index}`} style={{ 
            position: 'absolute',
            top: '50%', // Adjust these values to position the label over the icon
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center the label/icon
            cursor: 'pointer',
          }}>
            <AddCircleOutlineIcon style={{ color: 'rgb(255, 255, 255)' }} /> 
          </label>
          <input
            type="file"
            id={`sideImageUploadInput${index}`}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChangeSide(e, index)}
          />
        </div>
      </Root>
    ))}
  </div>
        </div>
      </>
  ) : (
  <>
  <div className="carousel-cell main">
    {sideImages.length > 0 && sideImages[0].tempUrl ? (
      <img src={sideImages[0].tempUrl} alt="" onClick={handleFlipBack} />
    ) : (
      <img src={sampleimage} alt="" onClick={handleFlipBack} />
    )}
  </div>
  <div className="side-images scrollbar4">
    {sideImages.map((image, index) => (
      image.tempUrl && (
        <div key={index} className="carousel-cell side-image" onClick={() => handleImageClick(index)}>
        <img
          src={image.tempUrl}
          alt={`Side ${index + 1}`}
        />
        </div>
      )
    ))}
  </div>
  </>
  )}
              <div className='FirstColumn'>
    <div className='assetTypeContainer'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Type')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '20px'}}>
    <Select
      value={editedAssetType}
      onChange={(e) => setEditedAssetType(e.target.value)}
      variant="standard"
      onOpen={() => {
        setMenuOpen(true);
        setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
      }}
      onClose={() => {
        setMenuOpen(false);
        setActiveElement(null); // Reset activeElement to null when the dropdown is closed
      }}
      className={classes.select}
      classes={{ icon: classes.icon }} 
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          style: menuItemStyle
        },
        getcontentanchorel: null, // This prevents the menu from being positioned based on the label
        classes: {
          paper: classes.paper,
          list: classes.item,
        },
      }}
    >
      {assetTypes.map((type) => (
        <MenuItem  key={type} value={type}
        onMouseEnter={() => setHoveredItem(type)}
        onMouseLeave={() => setHoveredItem(null)}
        style={hoveredItem === type ? hoveredMenuItemStyle : menuItemStyle}
        >
          {type}
          </MenuItem>
      ))}
    </Select>
    </div>
  )
  : (
    <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedAssetType)}</Typography>
  )}
    </div>
    <div className='totalFloorCount'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Total Floor Count')}</Typography>
      {isEditing ? (
     <div style={{marginLeft: '20px'}}>
        <TextField type="number"  
        variant="standard"  
        inputProps={{ 
        min: 0,
        step: 1,
        style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '20px', paddingTop: '15px', fontFamily: 'Montserrat'}
      }}
      InputProps={{
        classes: {
          underline: classes.underline,
        },
      }}
      value={editedTotalFloorCount} 
      onChange={(e) => setEditedTotalFloorCount(e.target.value)} />
    </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedTotalFloorCount)}</Typography>
      )}
    </div>
    <div className='FloorCount'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Floor')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '20px'}}>
        <TextField type="number"
        variant="standard"
        inputProps={{ 
          min: 0,
          step: 1,
          style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '20px', paddingTop: '15px', fontFamily: 'Montserrat'}
        }}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
         value={editedFloor} onChange={(e) => setEditedFloor(e.target.value)} />
         </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedFloor)}</Typography>
      )}
    </div>
    <div className='numberOfRoomsContainer'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat'}}>{t('Number of rooms')}</Typography>
      {isEditing ? (
           <div style={{marginLeft: '20px'}}>
       <TextField 
       type="text" 
       variant="standard"
       value={editedNumberOfRooms} 
       onChange={(e) => {
         const value = e.target.value;
         if (/^\d*\.?\d*$/.test(value)) { // Check if the input is a valid float number
           setEditedNumberOfRooms(value);
         }
       }} 
       inputProps={{ 
        min: 0,
        step: 1,
        style: { color: '#FFF', fontWeight: 400, fontSize: '15px', fontStyle: 'normal', marginLeft: '20px', paddingTop: '15px', fontFamily: 'Montserrat'}
      }}
      InputProps={{
        classes: {
          underline: classes.underline,
        },
      }}
     />
     </div>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '30px', fontFamily: 'Montserrat', marginBottom: '15px'}}>{t(editedNumberOfRooms)}</Typography>
      )}
    </div>
  </div>
              <div className='detailsContainer' style={{marginRight: '50px'}}>
              <div className='Viewing'>
              <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t('Viewing')}</Typography>
              <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '17px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat', textTransform: 'capitalize', }}>Mondays & Thursdays</Typography>
              <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat', marginBottom: '15px'}}>Call for appointment</Typography>
                </div>
                <div className='Amenities'>
    <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t('Amenities')}</Typography>
    <div className={classes.scrollableDiv}>
    {(Array.isArray(isEditing ? amenitiesList : amenities) ? (isEditing ? amenitiesList : amenities) : (typeof amenities === 'string' ? (isJsonString(amenities) ? JSON.parse(amenities) : amenities.split(',')) : [])).map((amenity, index) => (
    <div key={typeof amenity === 'string' ? index : amenity.id} style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginRight: '10px', fontFamily: 'Montserrat', width: '149px', marginLeft: '20px'}}>
        {t(amenity)}
      </Typography>
        {isEditing ? (
          <Checkbox
            style={{ padding: '6px' }}
            icon={<CircleIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checkedIcon={<TripOriginIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checked={checkedItems[amenity] || false} 
            onClick={() => {
              setCheckedItems(prevState => {
                return { ...prevState, [amenity]: !prevState[amenity] };
              });
            }}
          />
        ) : (
          <AmenitiesCheck />
        )}
      </div>
    ))}
  </div>
  </div>
                </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginLeft: '30px'}} >
    <div className='Furniture'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t('Furniture')}</Typography>
      {isEditing ? (
        <Select 
        value={editedFurniture}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedFurniture(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        >
   {["Furnished", "semi furnished", "empty"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            style={hoveredItem === item ? hoveredMenuItemStyle : menuItemStyle}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat', textTransform: 'capitalize'}}>{t(furniture)}</Typography>
      )}
    </div>
    <div className='AssetCondition'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t('Asset Condition')}</Typography>
      {isEditing ? (
        <Select 
        value={editedAssetCondition}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedAssetCondition(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        >
        {["Lux", "Original state", "Renovated"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            style={hoveredItem === item ? hoveredMenuItemStyle : menuItemStyle}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t(asset_condition)}</Typography>
      )}
    </div>
    <div className='Heating'>
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 300, fontSize: '20px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t('Heating')}</Typography>
      {isEditing ? (
        <Select 
        value={editedHeating}
        variant="standard"
        onOpen={() => {
          setMenuOpen(true);
          setActiveElement('menu'); // Set activeElement to 'menu' when the dropdown is opened
        }}
        onClose={() => {
          setMenuOpen(false);
          setActiveElement(null); // Reset activeElement to null when the dropdown is closed
        }}
        onChange={(e) => setEditedHeating(e.target.value)}
        className={classes.select}
        classes={{ icon: classes.icon }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: menuItemStyle
          },
          getcontentanchorel: null, // This prevents the menu from being positioned based on the label
          classes: {
            paper: classes.paper,
            list: classes.item,
          },
        }}
        >
  {["Central heating", "Gas", "Heat pumps", "Norwegian radiators", "EG", "TA"].map((item) => (
          <MenuItem 
            key={item}
            value={item}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            style={hoveredItem === item ? hoveredMenuItemStyle : menuItemStyle}
          >
            {t(item)}
          </MenuItem>
        ))}
        </Select>
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 300, fontSize: '18px', fontStyle: 'normal', marginLeft: '20px', fontFamily: 'Montserrat'}}>{t(heating)}</Typography>
      )}
    </div>
  </div>
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{display: 'flex', flexDirection: 'column', width: '70%', marginTop: '15px', alignItems: 'center'}}>
      {isEditing && editedAssetStatus === "FOR RENT" && (
        <>
          <Typography style={{color: 'rgb(0, 208, 189)', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat', marginBottom: '10px'}}>
            {t('Availability')}
          </Typography>
          <CalendarComponent onDateChange={handleDateChange} state={state} setState={setState} />
        </>
      )}
    </div>
  </div>
  <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '50px', marginTop: '20px'}}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor: 'rgb(0, 208, 189)', width: value === 0 ? '170px' : '100px'}}}>
      <Tab label={t('Description/Notes')}{...a11yProps(0)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '170px'}} />
      <Tab label={t('Clients')} {...a11yProps(1)} style={{textTransform: 'capitalize', fontSize: '20px', marginRight: '20px', color: 'rgb(0, 208, 189)', minWidth: '100px'}} />
    </Tabs>
  </Box>
  <CustomTabPanel value={value} index={0}  style={{marginLeft: '30px'}}>
    <div className="descriptionContainer">
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '20px', fontStyle: 'normal',  fontFamily: 'Montserrat'}}>
        {t('Description')}
      </Typography>
      {isEditing ? (
        <TextField
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.target.value)}
          className={classes.textField}
          multiline
        />
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', paddingTop: '15px', paddingRight: '15px', fontFamily: 'Montserrat'}}>
          {description}
        </Typography>
      )}
    </div>
    <div className="notesContainer">
      <Typography variant="body1" style={{ color: '#00D0BD', fontWeight: 500, fontSize: '20px', fontStyle: 'normal', paddingTop: '15px', fontFamily: 'Montserrat'}}>
        {t('Notes')}
      </Typography>
      {isEditing ? (
        <TextField
          value={editedNotes}
          onChange={(e) => setEditedNotes(e.target.value)}
          className={classes.textField}
          multiline
        />
      ) : (
        <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '17px', fontStyle: 'normal', paddingTop: '15px', paddingRight: '15px', fontFamily: 'Montserrat'}}>
          {notes}
        </Typography>
      )}
    </div>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={1}  style={{marginLeft: '30px'}}>
    <ClientsTab assetId={id} clientsTabRef={clientsTabRef} setMenuOpen={setMenuOpen} setActiveElement={setActiveElement} userId={userId} isEditing={isEditing}  isClientsTabActive={isClientsTabActive}/>
  </CustomTabPanel>
        
        {isEditing && (
        <div

        ref={buttonsBarRef} 
        style={{
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '10px',
          paddingTop: '10px',
          backgroundColor: '#1A2033' // Change this to match your modal's background color
        }}>
      <CustomAddCancelButton ref={saveButtonRef} onClick={handleSave}> Save </CustomAddCancelButton>
      <CustomAddCancelButton ref={cancelButtonRef} onClick={(e) => handleCancel(e)}> Cancel </CustomAddCancelButton>
        </div>
      )}
      </div>
      </div>
    </ThemeProvider>  </Root>)
  );
      };
      
  };

      return (
        <React.Fragment>
            {renderContent()}
        </React.Fragment>
    );
};

export default AssetDetailsModal;
