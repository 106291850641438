// Importing necessary dependencies and components
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

// Defining the RangeSlider component. It receives a value and an onChange function as props.
export default function RangeSlider({ value, onChange }) {

// a function to handle changes in the slider's value. It calls the onChange function received as a prop.
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };
 //  a function to format the value label of the slider. It returns a string representing the value in dollars.
  const valueLabelFormat = (value) => {
    if (value === 0) {
      return '$0';
    } else if (value >= 10000) {
      return '$10K +';
    } else {
      return `$${value}`;
    }
  };

// Rendering the RangeSlider component. It includes a Box component from Material UI that contains a Slider component.
  // The Slider component receives several props to configure its behavior and appearance.
  return (
    <Box sx={{ maxWidth: 333 }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '20px', marginTop: '7px', }}>
        <span style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 300, lineHeight: 'normal', paddingLeft: '5px' }}>Min</span>
        <span style={{ color: '#FFFFFF', fontSize: '16px', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 300, lineHeight: 'normal', paddingRight: '2px' }}>Max</span>
      </div>
      <div style={{ paddingLeft: '13px', paddingRight: '13px' }}>
        <Slider
          getAriaLabel={() => 'Price range'}
          value={value}
          onChange={handleChange}
          min={0}
          max={10000}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          sx={{
            color: '#FFFFFF',
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'transparent',
              color: '#FFFFFF',
              fontSize: '10px',
              marginRight: '5px',
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#FFFFFF',
            },
            '& .MuiSlider-track': {
              backgroundColor: '#FFFFFF',
              height: '3px',
            },
            '& .MuiSlider-thumb': {
              backgroundColor: '#FFFFFF',
              width: '11px',
              height: '11px',
              '&:hover, &.Mui-focusVisible': {
                boxShadow: '0px 0px 0px 8px rgba(255, 255, 255, 0.16)',
              },
            },
          }}
        />
      </div>
    </Box>
  );
}
