import React from 'react';

const UncheckAssets = ({ onClick }) => {

  const handleClick = () => {
    // Call the onClick function passed from the parent component
    onClick();
  };
  return (
<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"   onClick={handleClick}>
<path d="M38.9688 21.5C38.9688 24.955 37.9442 28.3324 36.0247 31.2051C34.1053 34.0778 31.377 36.3168 28.185 37.639C24.993 38.9612 21.4806 39.3071 18.092 38.6331C14.7034 37.9591 11.5908 36.2953 9.14774 33.8523C6.70469 31.4092 5.04095 28.2966 4.36692 24.908C3.69288 21.5194 4.03882 18.007 5.36099 14.815C6.68316 11.623 8.92217 8.89476 11.7949 6.97527C14.6676 5.05577 18.045 4.03125 21.5 4.03125C26.1315 4.03614 30.5719 5.87816 33.8469 9.15313C37.1218 12.4281 38.9639 16.8685 38.9688 21.5ZM36.2813 21.5C36.2813 18.5765 35.4144 15.7187 33.7902 13.288C32.166 10.8572 29.8575 8.96266 27.1565 7.84391C24.4556 6.72515 21.4836 6.43243 18.6163 7.00277C15.749 7.57311 13.1153 8.98088 11.0481 11.0481C8.98089 13.1153 7.57311 15.749 7.00278 18.6163C6.43244 21.4836 6.72516 24.4556 7.84391 27.1565C8.96267 29.8575 10.8572 32.166 13.288 33.7902C15.7187 35.4143 18.5766 36.2812 21.5 36.2812C25.4189 36.2768 29.176 34.7181 31.947 31.947C34.7181 29.176 36.2768 25.4189 36.2813 21.5Z" fill="#00D0BD"/>
<g clipPath="url(#clip0_668_5783)">
<path d="M27.0625 16.9375L16.9375 27.0625" stroke="#00D0BD" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M27.0625 27.0625L16.9375 16.9375" stroke="#00D0BD" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_668_5783">
<rect width="18" height="18" fill="white" transform="translate(13 13)"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default UncheckAssets;
