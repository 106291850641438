// styles.js
// Styles used for AccountSettingsModal.js 

import { createTheme, makeStyles } from '@material-ui/core/styles';

const defaultColor = "lightgray";
const hoverColor = "rgb(0, 208, 189)";
const focusColor = "rgb(0, 208, 189)";

// Define the theme
export const theme = createTheme({
    overrides: {
        MuiOutlinedInput: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: hoverColor,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: focusColor,
            },
          },
          notchedOutline: {
            borderColor: defaultColor,
          },
        },
      },
});

// Shared span styles for buttons
export const spanStyle = {
  fontWeight: '400',
  fontFamily: 'Montserrat',
  fontSize: '15px',
};

// Define styles for the modal and form
export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(rgb(42, 47, 61) 100%, rgba(65, 0, 51, 0) 100%)',
    padding: '20px',
    borderRadius: '5px',
    zIndex: 1009,
    maxWidth: '500px',
    width: '90%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 400,
  },
  closeButton: {
    marginLeft: 'auto',
    color: 'white',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  textField: {
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInputLabel-root': {
      color: 'darkgray',
    },
  },
  button: {
    textTransform: 'capitalize',
    color: '#FFF',
    backgroundColor: '#0E1529',
    fontFamily: 'Montserrat',
    boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)',
    transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out',
    borderRadius: '10px',
    maxWidth: '220px',
    '&:hover': {
      boxShadow: '0px 6px 20px rgba(0, 208, 189, 0.9)',
      color: 'rgb(0, 208, 189)',
    },
    '&:focus': {
      boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)',
      backgroundColor: '#0E1529',
      color: 'white',
    },
  },
}));
