import React, { useEffect, useState } from 'react';
import { IconButton,  ListItem, ListItemText} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import {useSwipeable} from 'react-swipeable';

import { useTranslation } from 'react-i18next';
import CheckAssetCircle from '../assets/CheckAssetCircle';
const useStyles = makeStyles((theme) => ({
  gridItemContainer: {
    position: 'relative',
    flexBasis: '100%', // Default to full width
    [theme.breakpoints.up('sm')]: {
      flexBasis: '40%', // 2 items per row on small devices
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '30.33%', // 3 items per row on medium devices
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: '23%', // 4 items per row on large devices
    },
  },
  checkboxContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  gridItemContainerMobile: {
position: 'relative',
flexBasis: '100%'
  },
  stepperDots: {
    backgroundColor: '#525866',
  },
  activeDot: {
    backgroundColor: 'white',
  }
}));

const RenderListItem = ({ data, handleListItemClick, handleCheckboxChange, isChecked, isSelectAllChecked   }) => {

  const { t } = useTranslation();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        console.log('Next picture');
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
      };
    
      const handleStepChange = (step) => {
        setActiveStep(step);
      };

  // Call useSwipeable after handleNext and handleBack are defined
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handleBack
  });

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  
  // Handle checkbox change
  const handleChange = () => {
    const newIsChecked = !isChecked; // Toggle isChecked state

    if (newIsChecked) {
      console.log('Selected asset:', data);
      handleCheckboxChange(data, newIsChecked); // Pass the updated state to the parent componenty
    } else if (isSelectAllChecked){
      handleCheckboxChange(data, !isSelectAllChecked); // Pass the updated state to the parent componenty
    
    } else {
      console.log('Deselected asset:', data);
    }
  };

  let images = [];
  let numImages = 0;
  

  const maxSteps = numImages > 1 ? numImages : 0;

  
  const renderContent = () => {
    if (isMobile) {
        // Placeholder for mobile content
        return (
            <ListItem key={data.id} style={{ borderRadius: '10px', border: '1px solid #1F2C41',  marginBottom: '5px', cursor: 'pointer'}}>
    <ListItemText primary={data.name} style={{ color: '#00D0BD', fontWeight: 500 , paddingRight: '10px', maxWidth: '200px'}} />
    <ListItemText primary={data.address} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} />
    <ListItemText primary={`${data.squareFootage} ft²`} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px', maxWidth: '250px'}} />
    <ListItemText primary={`€${data.price}`}style={{ color: '#F4F4F4', fontWeight: 500 , maxWidth: '150px'}}/>
          <IconButton onClick={() => handleChange(data)} style={{ position: 'absolute', top: 0, right: '40px' }}>
          <CheckAssetCircle isChecked={isChecked} handleChange={() => handleCheckboxChange(data, !isChecked)}  style={{ left: '20%', position: 'relative' ,  width: '30px', height: '30px'}} />
          </IconButton>
  </ListItem>
        );
    } else {
        // Placeholder for desktop content
        return (
            <ListItem key={data.id} style={{ borderRadius: '10px', border: '1px solid #1F2C41',  marginBottom: '5px', cursor: 'pointer'}}>
    <ListItemText primary={data.name} style={{ color: '#00D0BD', fontWeight: 500 , paddingRight: '10px', maxWidth: '200px'}}  onClick={() => handleListItemClick(data)} />
    <ListItemText primary={data.address} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}}  onClick={() => handleListItemClick(data)}/>
    <ListItemText primary={`${data.squareFootage} ft²`} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px', maxWidth: '250px'}}  onClick={() => handleListItemClick(data)} />
    <ListItemText primary={`€${data.price}`}style={{ color: '#F4F4F4', fontWeight: 500 , maxWidth: '150px'}}  onClick={() => handleListItemClick(data)}/>
          <IconButton onClick={() => handleChange(data)} style={{ position: 'absolute', top: 0, right: '40px' }}>
          <CheckAssetCircle isChecked={isChecked} handleChange={() => handleCheckboxChange(data, !isChecked)}  style={{ left: '20%', position: 'relative' ,  width: '30px', height: '30px'}} />
          </IconButton>
  </ListItem>
        );
    }
};

return (
  <React.Fragment>
  {renderContent()}

</React.Fragment>
);
};

export default RenderListItem;
