import React from 'react';
import IconButton from '@mui/material/IconButton';

// Define a React component that wraps IconButton
const CustomAddCancelButton = React.forwardRef((props, ref) => {
  // Inline styles for the default state
  const defaultStyle = {
    textTransform: 'capitalize',
    color: '#FFF',
    backgroundColor: '#0E1529',
    fontFamily: 'Montserrat',
    boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)',
    transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out',
    fontSize: '18px',
    borderRadius: '10px',
    marginRight: '10px'
  };

  // Function to handle mouse over event
  const handleMouseOver = (e) => {
    e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)';
    e.currentTarget.style.color = 'rgb(0, 208, 189)';
  };

  // Function to handle mouse out event
  const handleMouseOut = (e) => {
    e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)';
    e.currentTarget.style.color = '#FFF';
  };

  return (
    <IconButton
      ref={ref}
      {...props}
      style={defaultStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
});

export { CustomAddCancelButton };