import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, List, ListItem, ListItemText, ThemeProvider, createTheme } from '@mui/material';
import Close from '@material-ui/icons/Close';
import shareIconIphone from './img/shareIconIphone.png';

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || 
         (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
});

const InstallPWAInstructions = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isIOS()) {
      setShowModal(true);
    }
  }, []);
  const style = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '25px',
    boxShadow: 1,
    p: 2,
  };
  return (
    <ThemeProvider theme={theme}>
      {showModal && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1000',
        }}>
          <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Installation Instructions
              </Typography>
              <Close onClick={() => setShowModal(false)} sx={{ cursor: 'pointer' }} />
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '15px' }}>
              Install the app on your device to easily access it anytime. No need for app store or download.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={<><span>1. Tap on </span><img src={shareIconIphone} alt="Share Icon" style={{ verticalAlign: 'middle' }} /></>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<><span>2. Select 'Add to Home Screen'.</span></>}/>
              </ListItem>
            </List>
          </Box>
        </div>
      )}
    </ThemeProvider>
  );
};

export default InstallPWAInstructions;