import React from 'react';

const CurrencyCircleDollar = ({ fill }) => {

  return (
<svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_605_6587)">
<path d="M25 14.0625V17.1875" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25 32.8125V35.9375" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25 43.75C35.3553 43.75 43.75 35.3553 43.75 25C43.75 14.6447 35.3553 6.25 25 6.25C14.6447 6.25 6.25 14.6447 6.25 25C6.25 35.3553 14.6447 43.75 25 43.75Z" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.3125 32.8125H27.3438C28.3798 32.8125 29.3733 32.4009 30.1059 31.6684C30.8384 30.9358 31.25 29.9423 31.25 28.9062C31.25 27.8702 30.8384 26.8767 30.1059 26.1441C29.3733 25.4116 28.3798 25 27.3438 25H22.6562C21.6202 25 20.6267 24.5884 19.8941 23.8559C19.1616 23.1233 18.75 22.1298 18.75 21.0938C18.75 20.0577 19.1616 19.0642 19.8941 18.3316C20.6267 17.5991 21.6202 17.1875 22.6562 17.1875H29.6875" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_605_6587">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
  
export default CurrencyCircleDollar;
